import React, { useContext, useEffect, useState } from 'react'
import { IconButton, LinearProgress, Stack, Typography, Button } from '@mui/material'
import { HeaderContext } from 'src/context/header/headerContext'
import { AlertContext } from 'src/context/alert/alertContext'
import { labels } from 'src/labels/main_labels'
import { useParams, useHistory } from 'react-router-dom'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { grey } from '@mui/material/colors'
import { getBookingData } from 'src/services/bookings.services'
import { getCampaingData } from 'src/services/campaigns.services'
import { ContainerFud } from 'src/styles/general-styles'
import TextInput from 'src/components/Form/TextInput'
import { dateTimeConvert } from 'src/utils/time-helpers'
import { getIncidentData } from 'src/services/incidents.services'
import { saveAs } from 'file-saver'
import { Document, Page, Text, View, StyleSheet, pdf, Image } from '@react-pdf/renderer'

// rename helper for react18 overload
const MyDocument: any = Document
const MyPage: any = Page

// Create styles for PDF
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  text: {
    fontSize: 14,
    fontFamily: 'Courier',
    fontWeight: 'normal'
  }
})

export default function BookingsDetail(): JSX.Element {
  const { idCita } = useParams<{ idCita: string }>()
  const history = useHistory()

  const { showHeader } = useContext(HeaderContext)
  const { showSnackbar } = useContext(AlertContext)
  const [data, setData] = useState<any | null>(null)
  const [campaign, setCampaign] = useState<any | null>(null)
  const [incident, setIncident] = useState<any | null>(null)
  const [loading, setLoading] = useState(false)

  const MyDocumentPdf = (data: any) => (
    <MyDocument>
      <MyPage size="A4" style={styles.page} orientation={'portrait'}>
        <View style={styles.section}>
          <Text style={styles.text}>Compañia: {data?.values.company.name}</Text>
          <Text style={styles.text}>
            {labels.es.store}: {data?.values.store.name}
          </Text>
          <Text style={styles.text}>
            {labels.es.patient}: {data?.values?.patient.name}
          </Text>
          {data.values?.bookingReason && (
            <Text style={styles.text}>
              {labels.es.bookingReason}: {data?.values?.bookingReason?.name}
            </Text>
          )}
          <Text style={styles.text}>
            {labels.es.date}: {data.values.date ? dateTimeConvert(data.values.date, true) : ''}
          </Text>
          <Text style={styles.text}>
            {labels.es.timezone}: {data?.values?.hourFormatted}
          </Text>

          {data.values.campaign && !data.values.campaign.deleted && (
            <>
              <Text style={styles.text}>
                {labels.es.campaignName}: {data?.values?.campaign.name}
              </Text>
              <Text style={styles.text}>
                {labels.es.userResponsable}: {data?.values?.campaign.all.userResponsable.name}
              </Text>
              <Text style={styles.text}>
                {labels.es.description}: {data?.values?.campaign.all.description}
              </Text>
              <Text style={styles.text}>
                {labels.es.sector}: {data?.values?.campaign.all.sector.name}
              </Text>
              <Text style={styles.text}>
                {labels.es.process}: {data?.values?.campaign.all.process.name}
              </Text>
              <Text style={styles.text}>
                {labels.es.product}: {data?.values?.campaign.all.product}
              </Text>
              <Text style={styles.text}>
                {labels.es.machine}: {data?.values?.campaign.all?.machine?.name}
              </Text>
            </>
          )}

          {incident && (
            <>
              <Text style={styles.text}>
                {labels.es.incident}: {data?.values?.incident.name}
              </Text>
              <Text style={styles.text}>
                {labels.es.userResponsable}: {data?.values?.incident.all.userResponsable.name}
              </Text>
              <Text style={styles.text}>
                {labels.es.description}: {data?.values?.incident.all.description}
              </Text>
              <Text style={styles.text}>
                {labels.es.workWindow}: {data?.values?.incident.all.timeWindow.name}
              </Text>
              <Text style={styles.text}>
                {labels.es.sector}: {data?.values?.incident.all.sector.name}
              </Text>
              <Text style={styles.text}>
                {labels.es.process}: {data?.values?.incident.all.process.name}
              </Text>
              <Text style={styles.text}>
                {labels.es.product}: {data?.values?.incident.all.product}
              </Text>
              <Text style={styles.text}>
                {labels.es.machine}: {data?.values?.incident.all.machine.name}
              </Text>
              <Text style={styles.text}>
                {labels.es.affectedZone}: {data?.values?.incident.affectedZone.name}
              </Text>
              <Text style={styles.text}>
                {labels.es.daysSinceDayOff}: {data?.values?.incident.all.daysSinceDayOff}
              </Text>
              <Text style={styles.text}>
                {labels.es.whatHappened}: {data?.values?.incident.all.whatHappened}
              </Text>
              <Text style={styles.text}>
                {labels.es.when}: {data?.values?.incident.all.when ? dateTimeConvert(data.values.incident.all.when, true) : ''}
              </Text>
              <Text style={styles.text}>
                {labels.es.where}: {data?.values?.incident.all.sector.name}
              </Text>
              <Text style={styles.text}>
                {labels.es.how}: {data?.values?.incident.all.how.name}
              </Text>
              <Text style={styles.text}>
                {labels.es.sessions}: {incident?.sessions}
              </Text>

              {data.values.incident.all.closed && (
                <>
                  <Text style={styles.text}>
                    {labels.es.closeReason}: {data?.values?.incident.all.closed.closeReason.name}
                  </Text>
                  <Text style={styles.text}>
                    {labels.es.closeDate}: {new Date(data.values.incident.all.closed.closedAt).toLocaleDateString('es-ES') || ''}
                  </Text>
                </>
              )}
              <Text style={styles.text}>
                {labels.es.why}: {data?.values?.incident.all.why}
              </Text>
              <Text style={styles.text}>
                {labels.es.whoParticipatedAnalyse}:
                {incident?.whoParticipatedAnalyse.map((elm: any, idx: number) => {
                  return elm?.name + ' '
                }) || ''}
              </Text>
              <Text style={styles.text}>
                {labels.es.testigos}:
                {incident?.testigos.map((elm: any, idx: number) => {
                  return elm?.name + ' '
                }) || ''}
              </Text>
            </>
          )}
          {data.values.affectedZone && (
            <Text style={styles.text}>
              {labels.es.affectedZone}: {data?.values?.affectedZone.name}
            </Text>
          )}
          <Text style={styles.text}>
            {labels.es.notes}: {data?.values?.notes}
          </Text>
          <Text style={styles.text}>Firmado: {data.values.signImg ? 'Si' : 'No'}</Text>
        </View>
        <Image
          style={{ position: 'absolute', bottom: 20, right: 0, height: 200, width: 200 }}
          src={require('../../../assets/icons/general/MetaphysioLogo.png')}
          cache={false}
        />
      </MyPage>
    </MyDocument>
  )

  const Header = () => {
    return (
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <IconButton sx={{ ml: '-14px' }} onClick={() => history.goBack()}>
          <ChevronLeftIcon sx={{ color: grey[500] }} />
        </IconButton>

        <Typography variant="subtitle1" component="h1">
          {labels.es.bookingDetail}
        </Typography>
        <div />
      </Stack>
    )
  }

  const fetchData = async () => {
    setLoading(true)
    try {
      const resp = await getBookingData(idCita)
      setData(resp)
      if (resp.campaign && resp.campaign.id) {
        const respCampaign = await getCampaingData(resp.campaign.id)
        setCampaign(respCampaign)
      }
      if (resp.incident && resp.incident.id) {
        const respIncident = await getIncidentData(resp.incident.id)
        setIncident(respIncident)
      }
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()

    showHeader(Header)
  }, [])

  return (
    <>
      {loading && <LinearProgress />}
      {data && (
        <ContainerFud>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              size="small"
              variant="outlined"
              // className={classes.newOrderButton}
              style={{ marginBottom: 10 }}
              onClick={async () => {
                const doc = <MyDocumentPdf values={data} />
                const asPdf = pdf() // {} is important, throws without an argument
                asPdf.updateContainer(doc)
                const blob = await asPdf.toBlob()
                saveAs(blob, `Cita#${data && data.bookingId}.pdf`)
              }}
            >
              Descargar PDF
            </Button>
          </div>
          {/* <TextInput noTopBorder disabled value={data.bookingId || ''} name="bookingId" text={labels.es.bookingId} placeholder={''} /> */}
          {data.company && <TextInput disabled value={data.company.name || ''} name="company" text={labels.es.company} placeholder={''} />}
          {data.store && <TextInput disabled value={data.store.name || ''} name="store" text={labels.es.store} placeholder={''} />}
          <TextInput
            disabled
            value={data.patient ? data.patient.name.toLowerCase() : ''}
            name="patient"
            text={labels.es.patient}
            placeholder={''}
            style={{ textTransform: 'capitalize' }}
          />
          {data.bookingReason && (
            <TextInput disabled value={data.bookingReason.name} name="patient" text={labels.es.bookingReason} placeholder={''} />
          )}
          {data.affectedZone && data.bookingReason.id === 'Patología Común' && (
            <TextInput disabled value={data.affectedZone.name} name="patient" text={labels.es.affectedZone} placeholder={''} />
          )}

          <TextInput
            disabled
            value={data.date ? dateTimeConvert(data.date, true) : ''}
            name="date"
            text={labels.es.date}
            placeholder={''}
          />
          <TextInput disabled value={data.hourFormatted || ''} name="hourFormatted" text={labels.es.timezone} placeholder={''} />

          {campaign && !campaign.deleted && (
            <>
              <TextInput
                disabled
                value={data.campaign ? data.campaign.name : ''}
                name="campaign"
                text={labels.es.campaignName}
                placeholder={''}
              />
              {/* <TextInput disabled value={campaign.campaignId || ''} name="campaign" text={labels.es.campaignId} placeholder={''} /> */}
              <TextInput
                disabled
                value={campaign.userResponsable.name || ''}
                name="campaign"
                text={labels.es.userResponsable}
                placeholder={''}
              />
              <TextInput disabled value={campaign.description || ''} name="campaign" text={labels.es.description} placeholder={''} />

              <TextInput
                disabled
                value={campaign.sector ? campaign.sector.name : ''}
                name="sector"
                text={labels.es.sector}
                placeholder={''}
              />
              <TextInput
                disabled
                value={campaign.process ? campaign.process.name : ''}
                name="process"
                text={labels.es.process}
                placeholder={''}
              />
              <TextInput disabled value={campaign.product || ''} name="product" text={labels.es.product} placeholder={''} />
              <TextInput
                disabled
                value={campaign.machine ? campaign.machine.name : ''}
                name="sector"
                text={labels.es.machine}
                placeholder={''}
              />
            </>
          )}

          {incident && (
            <>
              <TextInput disabled value={incident.name || ''} name="incident" text={labels.es.incident} placeholder={''} />
              {/* <TextInput
                disabled
                value={incident.incidentId}
                name="incidentId"
                text={labels.es.incidentId + ' ' + labels.es.incident}
                placeholder={''}
              /> */}
              <TextInput
                disabled
                value={incident.userResponsable.name}
                name="description"
                text={labels.es.userResponsable}
                placeholder={''}
              />
              <TextInput disabled value={incident.description} name="description" text={labels.es.description} placeholder={''} />
              <TextInput disabled value={incident.timeWindow.name} name="timeWindow" text={labels.es.workWindow} placeholder={''} />
              <TextInput
                disabled
                value={incident.sector ? incident.sector.name : ''}
                name="sector"
                text={labels.es.sector}
                placeholder={''}
              />
              <TextInput
                disabled
                value={incident.process ? incident.process.name : ''}
                name="sector"
                text={labels.es.process}
                placeholder={''}
              />
              <TextInput disabled value={incident.product} name="product" text={labels.es.product} placeholder={labels.es.product} />
              <TextInput
                disabled
                value={incident.machine ? incident.machine.name : ''}
                name="machine"
                text={labels.es.machine}
                placeholder={''}
              />
              {/* <TextInput
                disabled
                value={incident.affectedZone.name || ''}
                name="incident"
                text={labels.es.reason + ' ' + labels.es.incident}
                placeholder={''}
              /> */}
              <TextInput
                value={incident.affectedZone ? incident.affectedZone?.name : ''}
                name="affectedZone"
                text={labels.es.affectedZone}
                placeholder={labels.es.affectedZone}
              />
              <TextInput
                disabled
                value={incident.daysSinceDayOff}
                name="daysSinceDayOff"
                text={labels.es.daysSinceDayOff}
                placeholder={''}
              />
              <TextInput disabled value={incident.whatHappened || ''} name="incident" text={labels.es.whatHappened} placeholder={''} />
              <TextInput
                disabled
                value={incident.when ? dateTimeConvert(incident.when, true) : ''}
                name="when"
                text={labels.es.when}
                placeholder={''}
              />
              <TextInput disabled value={incident.sector.name || ''} name="where" text={labels.es.where} placeholder={''} />
              <TextInput disabled value={incident.how.name || ''} name="how" text={labels.es.how} placeholder={''} />
              <TextInput disabled value={incident.sessions || ''} name="sessions" text={labels.es.sessions} placeholder={''} />
              {incident.closed && (
                <>
                  <TextInput
                    disabled
                    value={incident.closed.closeReason.name || ''}
                    name="where"
                    text={labels.es.closeReason}
                    placeholder={''}
                  />
                  <TextInput
                    disabled
                    value={new Date(incident.closed.closedAt).toLocaleDateString('es-ES') || ''}
                    name="where"
                    text={labels.es.closeDate}
                    placeholder={''}
                  />
                </>
              )}

              <TextInput disabled value={incident.why || ''} name="why" text={labels.es.why} placeholder={''} />
              <TextInput
                disabled
                value={
                  incident.whoParticipatedAnalyse.map((elm: any, idx: number) => {
                    return elm?.name
                  }) || ''
                }
                name="why"
                text={labels.es.whoParticipatedAnalyse}
                placeholder={''}
              />

              <TextInput
                disabled
                value={
                  incident.testigos.map((elm: any, idx: number) => {
                    return elm?.name
                  }) || ''
                }
                name="why"
                text={labels.es.testigos}
                placeholder={''}
              />
            </>
          )}
          {campaign && (
            <TextInput
              disabled
              value={data.affectedZone ? data.affectedZone.name : ''}
              name="affectedZone"
              text={labels.es.affectedZone}
              placeholder={''}
            />
          )}
          <TextInput disabled value={data.notes || ''} name="notes" text={labels.es.notes} placeholder={''} />
          <TextInput disabled value={data?.createdBy?.toLowerCase()} name="createdBy" text={labels.es.createdBy} placeholder={''} />
          {data.signImg && <img src={data.signImg} width={'10%'} />}
        </ContainerFud>
      )}
    </>
  )
}
