import React, { useRef, useState, useEffect, useContext } from 'react'
import { Box, Button, CircularProgress, Typography, useTheme, useMediaQuery } from '@mui/material'
import SignatureCanvas from 'react-signature-canvas'
import { AuthContext } from 'src/context/auth/authContext'
import { AlertContext } from 'src/context/alert/alertContext'
import { useParams } from 'react-router-dom'
import moment from 'moment'

const BookingSign: React.FC = () => {
  const { idCita } = useParams<{ idCita: string }>() // Obtiene el bookingId desde los parámetros de la URL
  const refSign = useRef<any>(null)
  const { firebase } = useContext(AuthContext)
  const { showSnackbar } = useContext(AlertContext)
  const [loading, setLoading] = useState(false)
  const [bookingData, setBookingData] = useState<any | null>(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  // useEffect para cargar los datos de la reserva
  useEffect(() => {
    const fetchBooking = async () => {
      try {
        setLoading(true)
        const bookingDoc = await firebase.db.collection('bookings').doc(idCita).get()
        if (bookingDoc.exists) {
          const data = bookingDoc.data()
          setBookingData(data)

          // Si existe una firma previa, cargarla en el canvas
          if (data?.signImg) {
            refSign.current?.fromDataURL(data.signImg)
          }
        } else {
          showSnackbar('error', 'No se encontró la reserva.')
        }
      } catch (error) {
        console.error('Error al cargar la reserva:', error)
        showSnackbar('error', 'Error al cargar la reserva.')
      } finally {
        setLoading(false)
      }
    }

    if (idCita) {
      fetchBooking()
    }
  }, [idCita, firebase.db, showSnackbar])

  const handleSaveSignature = async () => {
    try {
      setLoading(true)
      const imgSign = refSign.current?.getTrimmedCanvas().toDataURL('image/png')
      const ref = firebase.files.ref().child(`bookings/${idCita}`)

      await ref.putString(imgSign, 'data_url')
      const url = await ref.getDownloadURL()

      await firebase.db.collection('bookings').doc(idCita).update({ signImg: url, completed: true })

      showSnackbar('success', 'Firma guardada correctamente')
    } catch (error) {
      console.error('Error al guardar la firma:', error)
      showSnackbar('error', 'Error al guardar la firma')
    } finally {
      setLoading(false)
    }
  }

  const handleClearSignature = () => {
    refSign.current?.clear()
  }

  if (loading && !bookingData) {
    return <Typography>Cargando datos de la reserva...</Typography>
  }

  if (!bookingData) {
    return <Typography>No se encontraron datos para esta reserva.</Typography>
  }

  const canSign = () => {
    if (bookingData.date) {
      // Crear la fecha del día siguiente a las 23:59
      const nextDay = new Date(bookingData.date)
      nextDay.setDate(nextDay.getDate() + 1) // Avanzar un día
      nextDay.setHours(23, 59, 0, 0) // Establecer 23:59

      // Crear la fecha del dia 10 minutos antes de la cita
      let dateAppoinment = new Date(bookingData.date) // Crear la fecha de la cita
      dateAppoinment.setHours(bookingData.hour) // Establecer la hora de la cita
      dateAppoinment.setMinutes(bookingData.minutes) // Establecer los minutos de la cita
      dateAppoinment = moment(dateAppoinment).subtract(10, 'minutes').toDate() // Restar 10 minutos

      if (moment().isAfter(dateAppoinment) && moment().isBefore(nextDay)) return true
      else return false
    }
    return false
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2} style={{ marginTop: '3rem' }}>
      <Typography variant="h6">Confirmación asistencia</Typography>
      <Typography>
        <strong>Paciente:</strong> {bookingData.patient?.name || 'No especificado'}
      </Typography>
      <Typography>
        <strong>Fecha:</strong> {`${bookingData.day}/${+bookingData.month + 1}/${bookingData.year} - ${bookingData.hourFormatted}`}
      </Typography>
      {bookingData.signImg ? (
        <>
          <Typography variant="body2">Esta cita ya ha sido confirmada ✅</Typography>
          <Typography variant="h6">Firma del paciente</Typography>
          <Box display="flex" justifyContent="center" gap={2}>
            <img src={bookingData.signImg} alt="Firma" style={{ width: 200, height: 100 }} />
          </Box>
        </>
      ) : canSign() ? (
        <>
          <SignatureCanvas
            penColor="black"
            canvasProps={{
              width: isMobile ? window.screen.width * 0.9 : 500,
              height: 200,
              className: 'sigCanvas canvasSignature'
            }}
            backgroundColor={'#eeeeee'}
            ref={refSign}
          />
          <Box display="flex" gap={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveSignature}
              disabled={loading || !!bookingData?.signImg} // Deshabilitado si ya existe una firma
            >
              {loading ? <CircularProgress size={24} /> : 'Guardar Firma'}
            </Button>
            <Button variant="outlined" color="primary" onClick={handleClearSignature}>
              Limpiar
            </Button>
          </Box>
        </>
      ) : (
        <Typography variant="body2" style={{ marginTop: '1rem', textAlign: 'center' }}>
          Firma de asistencia solo disponible desde 10 minutos antes de la sesión
        </Typography>
      )}
    </Box>
  )
}

export default BookingSign
