import React, { useContext, useEffect, useState, useRef } from 'react'
import Swal from 'sweetalert2'
import { Stack, useTheme, Box, Alert, IconButton } from '@mui/material'
import { deleteSwalConfig } from 'src/utils/swal.utils'
import SearchInput from 'src/components/SearchInput'
import Table from 'src/components/Table/Table'
import deleteElements from 'src/services/deleteElements'
import { HeaderContext } from 'src/context/header/headerContext'
import { AlertContext } from 'src/context/alert/alertContext'
import { AuthContext } from 'src/context/auth/authContext'
import { labels } from 'src/labels/main_labels'
import {
  getAllIncidentsByCompany,
  deleteIncident,
  getAllIncidentsByCompanyAndStore,
  getAllIncidentsByUser
} from 'src/services/incidents.services'
import { AddButton } from 'src/components/Buttons/AddButton'
import IncidentsAddEdit from './IncidentsAddEdit'
import SimpleSelect from 'src/components/Form/Select'
import { InfoContext } from 'src/context/info/infoContext'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import { useHistory } from 'react-router-dom'
import NoResultsLogo from 'src/components/NoResultsLogo'
import FooterForm from 'src/components/Form/FooterForm'
import Modal from 'src/components/Modals/Modal'
import SignatureCanvas from 'react-signature-canvas'

const headCells: any[] = [
  {
    idField: 'incidentId',
    align: 'left',
    disablePadding: true,
    label: 'ID',
    order: true,
    fieldtype: 'idIncident'
  },
  {
    idField: 'when',
    align: 'left',
    disablePadding: true,
    label: labels.es.date,
    order: true,
    fieldtype: 'date'
  },
  {
    idField: 'injuredWorker',
    align: 'left',
    disablePadding: true,
    label: labels.es.worker,
    order: true,
    fieldtype: 'string'
  },
  {
    idField: 'whatHappened',
    align: 'left',
    disablePadding: true,
    label: labels.es.reason,
    order: true,
    fieldtype: 'string'
  },
  {
    idField: 'affectedZone',
    align: 'left',
    disablePadding: true,
    label: labels.es.affectedZone,
    order: true,
    fieldtype: 'string'
  },
  {
    idField: 'userResponsable',
    align: 'left',
    disablePadding: true,
    label: labels.es.userResponsable,
    order: true,
    fieldtype: 'string'
  },
  {
    idField: 'signed',
    align: 'left',
    disablePadding: true,
    label: labels.es.signed,
    order: true,
    fieldtype: 'incident-sign'
  },
  {
    idField: 'closed',
    align: 'left',
    disablePadding: true,
    label: labels.es.status,
    order: true,
    fieldtype: 'incident-completed'
  }
]
export default function Incidents(): JSX.Element {
  const { userData, firebase } = useContext(AuthContext)
  const theme = useTheme()
  const { showHeader } = useContext(HeaderContext)
  const { showSnackbar } = useContext(AlertContext)
  const [data, setData] = useState<any | null>(null)
  const [rowsData, setRowsData] = useState<any | null>(null)
  const [editData, setEditData] = useState<any | null>(null)
  const [initHeader, setInitHeader] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  const [selected, setSelected] = useState<string[]>([])
  const [filter, setFilter] = useState<string>('')
  const { allCompanies, allStores } = useContext(InfoContext)
  const [filterStore, setFilterStore] = useState<string>('')
  const [filterStoreOptions, setFilterStoreOptions] = useState<any[] | never>([])
  const [incidentSelectedSign, setIncidentSelectedSign] = useState<any | null>(null)

  const history = useHistory()

  const [loadingSign, setLoadingSign] = useState(false)
  const refSign = useRef()
  const [openSignModal, setOpenSignModal] = useState(false)
  const closeSignModal = () => {
    setOpenSignModal(false)
  }

  const [openModal, setOpenModal] = useState(false)
  const closeModal = () => {
    setOpenModal(false)
    setEditData(null)
  }

  const fetchIncidentsList = async (companyId?: string) => {
    const compId = userData.role.id === 'role1' ? companyId : userData.companyId
    if (!compId) return
    setLoadingTable(true)
    try {
      if (userData.role.id === 'role1' || userData.role.id === 'role3') {
        const resp = await getAllIncidentsByCompany(compId)
        const data = resp.filter((row: any) => row.deleted !== true)
        setData(data)
        setRowsData(data)
      } else if (userData.role.id === 'role6') {
        const resp = await getAllIncidentsByUser(userData.id)
        const data = resp.filter((row: any) => row.deleted !== true)
        setData(data)
        setRowsData(data)
      } else {
        const resp = await getAllIncidentsByCompanyAndStore(userData.companyId, userData.storeId)
        const data = resp.filter((row: any) => row.deleted !== true)
        setData(data)
        setRowsData(data)
      }
      if (!initHeader) setInitHeader(true)
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    } finally {
      setLoadingTable(false)
    }
  }

  const onSign = async (data: Record<string, any>) => {
    setIncidentSelectedSign(data)
    setOpenSignModal(true)
  }

  const onSignSave = async () => {
    if (incidentSelectedSign.injuredWorker.id === userData.id) {
      try {
        setLoadingSign(true)
        const imgSign = (refSign as any).current?.getTrimmedCanvas().toDataURL('image/png')
        const ref = firebase.files.ref().child(`incidents/${incidentSelectedSign.id}injuredWorker`)

        ref.putString(imgSign, 'data_url').then(() => {
          firebase.files
            .ref()
            .child(`incidents/${incidentSelectedSign.id}injuredWorker`)
            .getDownloadURL()
            .catch((error: any) => console.log('error uploading sign', error))
            .then(async (url: string) => {
              await firebase.db.collection('incidents').doc(incidentSelectedSign.id).update({ signInjuredWorker: url })
            })
            .catch((error: any) => console.log('error saving sign', error))

            .then(async () => {
              showSnackbar('success', labels.es.incidentSignedOk)
              await fetchIncidentsList()
            })
            .catch((error: any) => console.log('error uploading sign', error))
        })
        setLoadingSign(false)
        setIncidentSelectedSign(null)
        closeSignModal()
      } catch (error) {
        console.log('error sign', error)
        setLoadingSign(false)
        setIncidentSelectedSign(null)
        showSnackbar('success', labels.es.signedError)
        closeSignModal()
      }
    } else if (incidentSelectedSign.userResponsable.id === userData.id) {
      try {
        setLoadingSign(true)
        const imgSign = (refSign as any).current?.getTrimmedCanvas().toDataURL('image/png')
        const ref = firebase.files.ref().child(`incidents/${incidentSelectedSign.id}resp`)

        ref.putString(imgSign, 'data_url').then(() => {
          firebase.files
            .ref()
            .child(`incidents/${incidentSelectedSign.id}resp`)
            .getDownloadURL()
            .catch((error: any) => console.log('error uploading sign', error))
            .then(async (url: string) => {
              await firebase.db.collection('incidents').doc(incidentSelectedSign.id).update({ signResponsable: url })
            })
            .catch((error: any) => console.log('error saving sign', error))

            .then(async () => {
              showSnackbar('success', labels.es.incidentSignedOk)
              await fetchIncidentsList()
            })
            .catch((error: any) => console.log('error uploading sign', error))
        })
        setLoadingSign(false)
        setIncidentSelectedSign(null)
        closeSignModal()
      } catch (error) {
        console.log('error sign', error)
        setLoadingSign(false)
        setIncidentSelectedSign(null)
        showSnackbar('success', labels.es.signedError)
        closeSignModal()
      }
    }
  }

  const handleSearch = async (word: string) => {
    if (!word) {
      if (data) setRowsData(data)
    } else {
      if (data) setRowsData(data.filter((incident: any) => incident.name.toLowerCase().includes(word.toLowerCase())))
    }
  }

  const Header = () => {
    return (
      <Stack direction="row" justifyContent="space-between">
        {userData.role.id !== 'role6' && (
          <>
            <SearchInput placeholder={labels.es.searchIncident} name="search" handleSubmit={handleSearch} clean fullwidth />
            <AddButton onClick={() => setOpenModal(true)} text={labels.es.incidentResearch} />
          </>
        )}
      </Stack>
    )
  }

  useEffect(() => {
    setFilterStore('')
    const loadfilterStoreOptions = allStores
      .filter((elm) => elm.companyId === filter)
      .map((elm) => ({
        value: elm.id,
        label: elm.storeName
      }))

    setFilterStoreOptions([{ value: '', label: labels.es.filterByStore }, ...loadfilterStoreOptions])
  }, [filter])

  useEffect(() => {
    // Cargamos filtros de tiendas para hr
    if (userData.role.id === 'role3') {
      const loadfilterStoreOptions = allStores
        .filter((elm) => elm.companyId === userData.companyId)
        .map((elm) => ({
          value: elm.id,
          label: elm.storeName
        }))

      setFilterStoreOptions([{ value: '', label: labels.es.filterByStore }, ...loadfilterStoreOptions])
    }
  }, [])

  useEffect(() => {
    if (userData) fetchIncidentsList()
  }, [])

  useEffect(() => {
    showHeader(Header)
  }, [data])

  useEffect(() => {
    if (filter) fetchIncidentsList(filter)
    if (!filter) setRowsData(null)
  }, [filter])

  const fetchStoreIncidents = async () => {
    setLoadingTable(true)
    try {
      const storeIncidents = await getAllIncidentsByCompanyAndStore(filter || userData.companyId, filterStore)

      if (storeIncidents) setRowsData(storeIncidents.filter((elm: any) => elm.deleted !== true))
      setLoadingTable(false)
    } catch (error) {
      setLoadingTable(false)
    }
  }

  useEffect(() => {
    if (filterStore) fetchStoreIncidents()
  }, [filterStore])

  const cleanFilters = () => {
    setFilter('')
    setFilterStore('')
  }

  const onDelete = async (data: any) => {
    Swal.fire(deleteSwalConfig(labels.es.incidents, theme.palette.primary.main)).then((result) => {
      if (result.isConfirmed) {
        deleteElements(data, deleteIncident)
          .then(() => {
            showSnackbar('success', labels.es.incidentDeleted)
            fetchIncidentsList(filter)
            closeModal()
            setSelected([])
          })
          .catch((error) => {
            if (error instanceof Error) showSnackbar('error', error.message)
          })
      }
    })
  }

  const onEdit = async (data: Record<string, any>) => {
    // const resp = await getCompanyById(data.id)
    setEditData(data)
    setOpenModal(true)
  }

  const filterOptions = [{ id: '', name: labels.es.filterByCompany }, ...allCompanies].map((elm) => ({
    value: elm.id,
    label: elm.name
  }))

  const onRowClick = (row: Record<string, any>) => {
    history.push(`/incidentes/${row.id}`)
  }

  return (
    <>
      <Table
        noCheckbox={userData.role.id !== ('role1' || 'role3')}
        headCells={headCells}
        rows={rowsData}
        snackBar
        onSign={onSign}
        onDelete={onDelete}
        onEdit={onEdit}
        loading={loadingTable}
        onRowClick={onRowClick}
        selected={selected}
        setSelected={setSelected}
        initialOrderBy="createdAt"
        initialOrder="desc"
        filters={
          userData.role.id === 'role1' ? (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                '> div': { ml: 2, my: theme.breakpoints.down('sm') ? 0.5 : 0 },
                [theme.breakpoints.down('sm')]: {
                  overflowX: 'scroll'
                }
              }}
            >
              <SimpleSelect
                minWidth={200}
                name="filters"
                data={filter}
                handleChange={(e) => setFilter(e.target.value)}
                options={filterOptions}
              />
              {filterStoreOptions && filter && (
                <SimpleSelect
                  minWidth={200}
                  name="filterStore"
                  data={filterStore}
                  handleChange={(e) => setFilterStore(e.target.value)}
                  options={filterStoreOptions}
                />
              )}
              <IconButton sx={{ mx: 2 }} color="primary" onClick={cleanFilters}>
                <FilterAltOffIcon />
              </IconButton>
            </Box>
          ) : userData.role.id === 'role3' ? (
            <>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  '> div': { ml: 2, my: theme.breakpoints.down('sm') ? 0.5 : 0 },
                  [theme.breakpoints.down('sm')]: {
                    overflowX: 'scroll'
                  }
                }}
              >
                {filterStoreOptions && (
                  <SimpleSelect
                    minWidth={200}
                    name="filterStore"
                    data={filterStore}
                    handleChange={(e) => setFilterStore(e.target.value)}
                    options={filterStoreOptions}
                  />
                )}
                <IconButton sx={{ mx: 2 }} color="primary" onClick={cleanFilters}>
                  <FilterAltOffIcon />
                </IconButton>
              </Box>
            </>
          ) : undefined
        }
      />
      {userData.role.id === 'role1' && (!rowsData || rowsData.length === 0) && (
        <Alert sx={{ backgroundColor: 'transparent', justifyContent: 'center' }} severity="warning">
          {filter ? 'No hay resultados disponibles' : 'Selecciona una compañia para ver resultados'}
        </Alert>
      )}
      {(!rowsData || rowsData.length === 0) && <NoResultsLogo />}
      <IncidentsAddEdit
        openModal={openModal}
        closeModal={closeModal}
        dataToEdit={editData}
        onDelete={onDelete}
        fetchData={() => fetchIncidentsList(filter)}
      />
      <Modal
        title={labels.es.confirmWithSignIncident}
        open={openSignModal}
        handleClose={closeSignModal}
        footer={
          <FooterForm
            text={labels.es.save}
            mainText
            closeModal={closeSignModal}
            onSave={onSignSave}
            loading={loadingSign}
            editing={false}
            // disabled={si no hay firma}
          />
        }
      >
        <SignatureCanvas penColor="black" canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }} ref={refSign} />
      </Modal>
    </>
  )
}
