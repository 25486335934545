import React, { useState, useEffect, useContext } from 'react'
import Box from '@mui/material/Box'
import TextInput from 'src/components/Form/TextInput'
import Modal from 'src/components/Modals/Modal'
import FooterForm from 'src/components/Form/FooterForm'
import { AddEditProps } from '../../../types/pages.types'
import { useForm } from 'src/hooks/useForm'
import { AlertContext } from 'src/context/alert/alertContext'
import { AuthContext } from 'src/context/auth/authContext'
import { InfoContext } from 'src/context/info/infoContext'
import { labels } from 'src/labels/main_labels'
import { createCampaing, updateCampaing } from 'src/services/campaigns.services'
import { getAllUsersByCompanyAndStore } from 'src/services/users.services'
import { getAllStoresByCompany } from 'src/services/stores.services'
import { getAllCompanyConfig } from 'src/services/companies.services'
import SelectDialog from 'src/components/Form/SelectDialog'

const INITIAL_VALUES = {
  description: '',
  product: ''
}

export default function CampaignsAddEdit({ dataToEdit, closeModal, openModal, onDelete, fetchData }: AddEditProps): JSX.Element {
  const editing = !!dataToEdit
  const { showSnackbar } = useContext(AlertContext)
  const { userData } = useContext(AuthContext)
  const { machines, processes, sectors, allCompanies } = useContext(InfoContext)
  const { handleChange, values, resetForm } = useForm(INITIAL_VALUES, dataToEdit)
  const [loading, setLoading] = useState(false)
  const [companyUsers, setCompanyUsers] = useState([])
  const [userResponsable, setUserResponsable] = useState<any | never>(null)
  const [processSelected, setProcessSelected] = useState<any | never>(null)
  const [machineSelected, setMachineSelected] = useState<any | never>(null)
  const [sectorSelected, setSectorSelected] = useState<any | never>(null)
  const [company, setCompany] = useState<any | never>(null)
  const [store, setStore] = useState<any | never>(null)
  const [allCompanyStores, setAllCompanyStores] = useState<any[]>([])
  const [configCompanyAdmin, setConfigCompanyAdmin] = useState([])

  const resetComponent = () => {
    setLoading(false)
    resetForm()
    setUserResponsable(null)
    setProcessSelected(null)
    setMachineSelected(null)
    setSectorSelected(null)
    setStore(null)
    setCompany(null)
    setAllCompanyStores([])
    setConfigCompanyAdmin([])
  }

  useEffect(() => {
    if (dataToEdit) {
      if (dataToEdit.process) setProcessSelected(dataToEdit.process)
      if (dataToEdit.sector) setSectorSelected(dataToEdit.sector)
      if (dataToEdit.machine) setMachineSelected(dataToEdit.machine)
      if (dataToEdit.userResponsable) setUserResponsable(dataToEdit.userResponsable)
      if (dataToEdit.company) setCompany(dataToEdit.company)
      if (dataToEdit.store) setStore(dataToEdit.store)
    }

    return () => resetComponent()
  }, [dataToEdit])

  const loadUsers = async () => {
    if (userData && userData.companyId && userData.storeId) {
      const users = await getAllUsersByCompanyAndStore(userData.companyId, userData.storeId)

      if (users && users.length) setCompanyUsers(users)
    }
  }

  const loadCompanyStores = async () => {
    if (userData.role.id !== 'role3') {
      const companyStores = await getAllStoresByCompany(company.id)

      if (companyStores && companyStores.length) setAllCompanyStores(companyStores)
    }
    if (userData.role.id === 'role3') {
      const companyStores = await getAllStoresByCompany(userData.companyId)

      if (companyStores && companyStores.length) setAllCompanyStores(companyStores)
    }
  }

  const loadCompanyConfig = async () => {
    const companyConfig = await getAllCompanyConfig(company.id)

    if (companyConfig && companyConfig.length) setConfigCompanyAdmin(companyConfig)
  }

  const loadAdminUsers = async () => {
    if (userData && userData.role.id === 'role1' && store && company) {
      const users = await getAllUsersByCompanyAndStore(company.id, store.id)
      if (users && users.length) {
        setCompanyUsers(users)
        if (!dataToEdit) setUserResponsable(null)
      } else {
        if (!dataToEdit) setUserResponsable(null)
        setCompanyUsers([])
      }
    } else if (userData && userData.role.id === 'role3' && store) {
      const users = await getAllUsersByCompanyAndStore(userData.companyId, store.id)
      if (users && users.length) {
        setCompanyUsers(users)
        if (!dataToEdit) setUserResponsable(null)
      } else {
        if (!dataToEdit) setUserResponsable(null)
        setCompanyUsers([])
      }
    }
  }
  useEffect(() => {
    if (userData && !dataToEdit && userData.role.id !== ('role1' || 'role3'))
      setUserResponsable({ name: userData.name + ' ' + userData.lastname + ' ' + userData?.lastname2, id: userData.id })

    if (userData.role.id === 'role3' && userData) loadCompanyStores()
  }, [userData, openModal])

  useEffect(() => {
    store && loadAdminUsers()
  }, [store])

  useEffect(() => {
    company && loadCompanyStores()
    company && loadCompanyConfig()
  }, [company])

  useEffect(() => {
    loadUsers()
  }, [])

  useEffect(() => {
    loadAdminUsers()
  }, [store])

  const onSave = async () => {
    setLoading(true)
    const newCampaing = JSON.parse(JSON.stringify(values))
    try {
      if (!dataToEdit) {
        newCampaing.createdAt = Date.now()
        newCampaing.updatedAt = Date.now()
        if (userData.role.id !== 'role1' && userData.role.id !== 'role3') {
          newCampaing.companyId = userData.companyId
          newCampaing.storeId = userData.storeId
          newCampaing.company = userData.company
          newCampaing.store = userData.store
        } else if (userData.role.id === 'role1') {
          newCampaing.companyId = company.id
          newCampaing.company = company
          newCampaing.storeId = store.id
          newCampaing.store = store
        } else if (userData.role.id === 'role3') {
          newCampaing.companyId = userData.companyId
          newCampaing.company = userData.company
          newCampaing.storeId = store.id
          newCampaing.store = store
        }

        newCampaing.userResponsable = userResponsable
        newCampaing.process = processSelected
        newCampaing.sector = sectorSelected
        newCampaing.machine = machineSelected

        await createCampaing(newCampaing)
        showSnackbar('success', labels.es.campaignsCreated)
      } else {
        newCampaing.updatedAt = Date.now()
        newCampaing.userResponsable = userResponsable
        newCampaing.process = processSelected
        newCampaing.sector = sectorSelected
        newCampaing.machine = machineSelected

        await updateCampaing(newCampaing)
        showSnackbar('success', labels.es.campaignsUpdated)
      }
      resetComponent()
      closeModal()
      if (fetchData) fetchData()
    } catch (error: any) {
      showSnackbar('error', error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = () => {
    if (dataToEdit && onDelete) onDelete(dataToEdit.id)
  }

  return (
    <Modal
      title={editing ? labels.es.editCampaign : labels.es.addCampaigns}
      open={openModal}
      handleClose={closeModal}
      footer={
        <FooterForm
          text={labels.es.campaign}
          closeModal={closeModal}
          onSave={onSave}
          onDelete={onDelete ? handleDelete : undefined}
          editing={editing}
          loading={loading}
          disabled={!values.description || !values.product || !sectorSelected || !processSelected || !userResponsable}
        />
      }
    >
      <Box id="form-campaign">
        {/* {editing && (
          <TextInput disabled value={values.campaignId} name="campaignId" text={labels.es.campaignId} placeholder={labels.es.campaignId} />
        )} */}
        {userData.role.id === 'role1' && (
          <SelectDialog
            text={labels.es.company}
            setData={setCompany}
            data={company}
            options={allCompanies.map((elm: any) => {
              return { name: elm.name, id: elm.id }
            })}
          />
        )}
        {allCompanyStores.length > 0 && ['role1', 'role3'].includes(userData.role.id) && (
          <SelectDialog
            text={labels.es.store}
            setData={setStore}
            data={store}
            options={allCompanyStores
              .filter((elm: any) => !elm.deleted)
              .sort((a, b) => a.storeName.localeCompare(b.storeName))
              .map((elm: any) => {
                return { name: elm.storeName, id: elm.id }
              })}
          />
        )}
        <TextInput
          required
          value={values.description}
          handleChange={(e) => handleChange(e)}
          name="description"
          text={labels.es.description}
          placeholder={labels.es.description}
          multiline
          rows={3}
        />
        {companyUsers.length > 0 && (
          <SelectDialog
            text={labels.es.userResponsable}
            setData={setUserResponsable}
            data={userResponsable}
            options={companyUsers.map((elm: any) => {
              return { name: `${elm.name} ${elm.lastname || ''} ${elm.lastname2 || ''}`, id: elm.id, employeeId: elm.employeeId }
            })}
          />
        )}
        {sectors.length > 0 && userData.role.id !== 'role1' && (
          <SelectDialog
            required
            text={labels.es.sector}
            setData={setSectorSelected}
            data={sectorSelected}
            options={sectors
              .filter((elm) => !elm.deleted && elm.sectorType.id === 'Almacén')
              .map((elm: any) => {
                return { name: elm.name, id: elm.id }
              })
              .sort((a, b) => a.name.localeCompare(b.name))}
          />
        )}
        {configCompanyAdmin && userData.role.id === 'role1' && (
          <>
            <SelectDialog
              required
              text={labels.es.sector}
              setData={setSectorSelected}
              data={sectorSelected}
              options={configCompanyAdmin
                .filter((elm: any) => elm.type === 'sectors' && !elm.deleted && elm.sectorType.id === 'Almacén')
                .sort((a: any, b: any) => a.name.localeCompare(b.name))
                .map((elm: any) => {
                  return { name: elm.name, id: elm.id }
                })}
            />
          </>
        )}
        {processes.length > 0 && userData.role.id !== 'role1' && (
          <SelectDialog
            required
            text={labels.es.process}
            setData={setProcessSelected}
            data={processSelected}
            options={processes
              .filter((elm) => !elm.deleted)
              .map((elm: any) => {
                return { name: elm.name, id: elm.id }
              })
              .sort((a, b) => a.name.localeCompare(b.name))}
          />
        )}
        {configCompanyAdmin && userData.role.id === 'role1' && (
          <>
            <SelectDialog
              required
              text={labels.es.process}
              setData={setProcessSelected}
              data={processSelected}
              options={configCompanyAdmin
                .filter((elm: any) => elm.type === 'processes' && !elm.deleted)
                .sort((a: any, b: any) => a.name.localeCompare(b.name))
                .map((elm: any) => {
                  return { name: elm.name, id: elm.id }
                })}
            />
          </>
        )}
        <TextInput
          required
          value={values.product}
          handleChange={(e) => handleChange(e)}
          name="product"
          text={labels.es.product}
          placeholder={labels.es.product}
        />

        {machines.length > 0 && userData.role.id !== 'role1' && (
          <SelectDialog
            text={labels.es.machine}
            setData={setMachineSelected}
            data={machineSelected}
            options={machines
              .filter((elm) => !elm.deleted)
              .sort((a: any, b: any) => a.name.localeCompare(b.name))
              .map((elm: any) => {
                return { name: elm.name, id: elm.id }
              })
              .sort((a, b) => a.name.localeCompare(b.name))}
          />
        )}
        {configCompanyAdmin && userData.role.id === 'role1' && (
          <>
            <SelectDialog
              text={labels.es.machine}
              setData={setMachineSelected}
              data={machineSelected}
              options={configCompanyAdmin
                .filter((elm: any) => elm.type === 'machines' && !elm.deleted)
                .sort((a: any, b: any) => a.name.localeCompare(b.name))
                .map((elm: any) => {
                  return { name: elm.name, id: elm.id }
                })}
            />
          </>
        )}
      </Box>
    </Modal>
  )
}
