import React from 'react'
import { Avatar, Typography, Chip, Stack, Box } from '@mui/material'
import { green, orange, red } from '@mui/material/colors'
import { dateTimeConvert, parameterToTime } from 'src/utils/time-helpers'
import { labels } from 'src/labels/main_labels'

import { ReactComponent as LikeTable } from 'src/assets/icons/general/LikeTable.svg'
import { ReactComponent as DislikeTable } from 'src/assets/icons/general/DislikeTable.svg'
import { ReactComponent as SpecialVouchers } from 'src/assets/icons/general/SpecialVouchers.svg'
import { ReactComponent as UserOrange } from 'src/assets/icons/smalls/UserOrange.svg'
// import { ReactComponent as RecommendStar } from 'src/assets/icons/general/RecommendStar.svg'

import { stringAvatar } from 'src/utils/helpers'
import isAfter from 'date-fns/isAfter'
import { parseISO, startOfToday } from 'date-fns'

interface DisplayFieldsProps {
  col: any
  row: any
}

export default function DisplayFields({ col, row }: DisplayFieldsProps): JSX.Element {
  const { idField, fieldtype } = col
  const data = row[idField]

  switch (fieldtype) {
    case 'string':
      return (
        <Typography variant="body1" sx={{ color: '#6B7280' }}>
          {data && data.name ? data.name : data}
        </Typography>
      )
    case 'patient':
      return (
        <Typography variant="body1" sx={{ color: '#6B7280', textTransform: 'capitalize' }}>
          {data && data.name ? data.name.toLowerCase() : data.toLowerCase()}
        </Typography>
      )
    case 'sectors':
      return (
        <Typography variant="body1" sx={{ color: '#6B7280' }}>
          {data && typeof data === 'object' && data.name
            ? data.name
            : data?.map((sector: any, idx: number) => (idx < data.length - 1 ? `${sector.name},` : sector.name))}
        </Typography>
      )
    case 'boolean':
      return (
        <>
          {data ? (
            <Chip size="small" label={labels.es.completed} sx={{ backgroundColor: green[100], color: green[800] }} />
          ) : (
            <Chip size="small" label={labels.es.pending} sx={{ backgroundColor: red[100], color: red[800] }} />
          )}
        </>
      )
    case 'booking-completed':
      return (
        <>
          {data ? (
            <Chip
              style={{ borderColor: '#0079c8' }}
              variant="outlined"
              size="small"
              label={labels.es.completed}
              sx={{ backgroundColor: '#0079c8', color: 'white' }}
            />
          ) : isAfter(startOfToday(), parseISO(row.date)) ? (
            <Chip
              style={{ borderColor: red[800] }}
              variant="outlined"
              size="small"
              label={labels.es.noAssist}
              sx={{ backgroundColor: red[100], color: red[800] }}
            />
          ) : (
            <Chip
              style={{ borderColor: '#7f9aad' }}
              variant="outlined"
              size="small"
              label={labels.es.pending}
              sx={{ backgroundColor: '#f1f5f8', color: '#7f9aad' }}
            />
          )}
        </>
      )
    case 'incident-completed':
      return (
        <>
          {!data ? (
            <Chip
              style={{ borderColor: '#7f9aad' }}
              variant="outlined"
              size="small"
              label={labels.es.open}
              sx={{ backgroundColor: '#f1f5f8', color: '#7f9aad' }}
            />
          ) : data?.closeReason.id === 'sinresolución' ? (
            <Chip
              style={{ borderColor: red[800] }}
              variant="outlined"
              size="small"
              label={labels.es.noResolution}
              sx={{ backgroundColor: red[100], color: red[800] }}
            />
          ) : data?.closeReason.id === 'alta' ? (
            <Chip
              style={{ borderColor: '#0079c8' }}
              variant="outlined"
              size="small"
              label={labels.es.alta}
              sx={{ backgroundColor: '#0079c8', color: 'white' }}
            />
          ) : (
            data?.closed?.closeReason.id === 'full' && (
              <Chip
                style={{ borderColor: '#7f9aad' }}
                variant="outlined"
                size="small"
                label={labels.es.full}
                sx={{ backgroundColor: '#f1f5f8', color: '#7f9aad' }}
              />
            )
          )}
        </>
      )
    case 'incident-sign':
      return (
        <>
          {!row?.signResponsable && !row?.signInjuredWorker ? (
            <Chip
              style={{ borderColor: '#7f9aad' }}
              variant="outlined"
              size="small"
              label={'Faltan Ambas'}
              sx={{ backgroundColor: '#f1f5f8', color: '#7f9aad' }}
            />
          ) : !row?.signResponsable && row?.signInjuredWorker ? (
            <Chip
              style={{ borderColor: red[800] }}
              variant="outlined"
              size="small"
              label={'Falta Responsable'}
              sx={{ backgroundColor: red[100], color: red[800] }}
            />
          ) : row?.signResponsable && !row?.signInjuredWorker ? (
            <Chip
              style={{ borderColor: '#0079c8' }}
              variant="outlined"
              size="small"
              label={'Falta Colaborador'}
              sx={{ backgroundColor: '#0079c8', color: 'white' }}
            />
          ) : (
            row?.signResponsable &&
            row?.signInjuredWorker && (
              <Chip
                style={{ borderColor: '#7f9aad' }}
                variant="outlined"
                size="small"
                label={'Firmado'}
                sx={{ backgroundColor: '#f1f5f8', color: '#7f9aad' }}
              />
            )
          )}
        </>
      )
    case 'label':
      return (
        <Typography variant="body1" sx={{ color: '#6B7280' }}>
          {labels.es[data]}
        </Typography>
      )
    case 'number':
      return <Typography variant="body1">{data || 0}</Typography>
    case 'name':
      return (
        <Typography variant="subtitle2" sx={{ color: '#111827', textTransform: 'capitalize' }}>
          {data?.toLowerCase()}
          {row.deleted && (
            <Chip
              sx={{ ml: 1, backgroundColor: red[100], color: red[800] }}
              size="small"
              label={`${labels.es.deleted} ${dateTimeConvert(row.updatedAt)}`}
            />
          )}
        </Typography>
      )
    case 'name-avatar':
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* <Avatar variant="rounded" sx={{ width: 40, height: 40 }} src={row.photo} /> */}
          {!row.photo || row.photo === 'null' ? (
            <Avatar variant="rounded" sx={{ width: 40, height: 40 }} alt={row.name} {...stringAvatar(row.name)} />
          ) : (
            <Avatar variant="rounded" sx={{ width: 40, height: 40 }} alt={row.name} src={row.photo} />
          )}
          <Typography variant="subtitle2" sx={{ ml: 2, color: '#111827' }}>
            {data}
          </Typography>
          {(row.status === 'INACTIVE' || row.status === 'DISABLED') && (
            <Chip sx={{ ml: 1 }} color="secondary" size="small" label={labels.es.deactivated} />
          )}
          {row.status === 'PRE_USER' && (
            <Chip sx={{ ml: 1, backgroundColor: '#DBEAFE', color: '#1E40AF' }} size="small" label={labels.es.preUser} />
          )}
          {row.status === 'DELETED' && (
            <Chip sx={{ ml: 1 }} size="small" color="warning" label={`${labels.es.deleted} ${dateTimeConvert(row.updatedAt)}`} />
          )}
        </div>
      )
    case 'user-avatar':
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {row.photo ? (
            <Avatar variant="circular" sx={{ width: 40, height: 40 }} alt={row.name} src={row.photo} />
          ) : (
            <Avatar variant="circular" sx={{ width: 40, height: 40 }} alt={row.name} {...stringAvatar(row.name, row.surname)} />
          )}
          <Stack>
            <Stack direction="row" sx={{ alignItems: 'center' }}>
              <Typography variant="subtitle2" sx={{ ml: 2, color: '#111827', fontWeight: 500 }}>
                {data} {row.surname || ''}
              </Typography>
              {row.status === 'INACTIVE' && <Chip sx={{ ml: 1 }} color="secondary" size="small" label={labels.es.deactivated} />}
              {row.status === 'PRE_USER' && (
                <Chip sx={{ ml: 1, backgroundColor: '#DBEAFE', color: '#1E40AF' }} size="small" label={labels.es.preUser} />
              )}
              {row.status === 'DELETED' && (
                <Chip sx={{ ml: 1 }} size="small" color="warning" label={`${labels.es.deleted} ${dateTimeConvert(row.updatedAt)}`} />
              )}
            </Stack>
            <Typography variant="subtitle2" sx={{ ml: 2, color: '#6B7280', fontWeight: 400 }}>
              {row.email || ''}
            </Typography>
          </Stack>
        </div>
      )
    case 'voucher-name':
      return (
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <Typography variant="subtitle2" sx={{ color: '#111827' }}>
            {data}
          </Typography>
          {row.special && row.status === 'ACTIVE' && (
            <Box sx={{ ml: 1 }}>
              <SpecialVouchers />
            </Box>
          )}
          {row.position && row.position !== '0' && (
            <Chip sx={{ ml: 1, backgroundColor: '#E0E7FF', color: '#3730A3' }} size="small" label={row.position} />
          )}
          {row.users && row.users.length > 0 && (
            <Chip
              sx={{
                flexDirection: 'row-reverse',
                pr: 1.5,
                '& .MuiChip-label': { pr: '1px' },
                ml: 1,
                backgroundColor: '#FFEDD5',
                color: '#9A3412'
              }}
              size="small"
              label={row.users.length}
              icon={<UserOrange />}
            />
          )}
        </Stack>
      )
    case 'date':
      return (
        <Typography variant="body1" sx={{ color: '#6B7280' }}>
          {row.when ? new Date(row.when).toLocaleDateString('es-ES') : ''}
        </Typography>
      )

    case 'discount-name':
      return (
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <Typography variant="subtitle2" sx={{ color: '#111827' }}>
            {data}
          </Typography>
          {row.special && row.status === 'ACTIVE' && (
            <Box sx={{ ml: 1 }}>
              <SpecialVouchers />
            </Box>
          )}
          {row.status === 'DISABLED' && <Chip sx={{ ml: 1 }} color="secondary" size="small" label={labels.es.deactivated} />}

          {row.expiration && Date.parse(row.expiration) < Date.now() && (
            <Chip sx={{ ml: 1, backgroundColor: '#FEF3C7', color: '#92400E' }} size="small" label={labels.es.expired} />
          )}
          {row.users && row.users.length > 0 && (
            <Chip
              sx={{
                flexDirection: 'row-reverse',
                pr: 1.5,
                '& .MuiChip-label': { pr: '1px' },
                ml: 1,
                backgroundColor: '#FFEDD5',
                color: '#9A3412'
              }}
              size="small"
              label={row.users.length}
              icon={<UserOrange />}
            />
          )}
        </Stack>
      )
    // case 'chips':
    // case 'chips-estilos':
    //   return (
    //     <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
    //       {data.length > 0 &&
    //         data.map((elm: string, idx: number) => (
    //           <ChipElement key={idx}>
    //             <Chip size="small" label={fieldtype === 'chips-estilos' ? styleFoodLabels.es[elm] : elm} variant="outlined" />
    //           </ChipElement>
    //         ))}
    //     </div>
    //   )
    case 'location':
      return (
        <div>
          {data && data.street && (
            <Typography variant="body1" sx={{ color: '#111827' }}>
              {data.street} {data.number || ''}
            </Typography>
          )}
          {data && <Typography variant="body1">{data.cp}</Typography>}
        </div>
      )
    case 'money':
      return <Typography variant="body1">{data || '0,00'} €</Typography>
    case 'date-time':
      return <Typography variant="body1">{dateTimeConvert(data)}</Typography>
    case 'date-only':
      return (
        <Typography variant="body1">
          {new Date(data).getDate() + '/' + (new Date(data).getMonth() + 1) + '/' + new Date(data).getFullYear()}
        </Typography>
      )
    case 'plate-pickup':
      return (
        <Typography variant="body1">
          {data.IRr?.hour +
            ':' +
            String(data.IRr?.minutes).padStart(2, '0') +
            ' - ' +
            data?.FRr?.hour +
            ':' +
            String(data.FRr?.minutes).padStart(2, '0')}
        </Typography>
      )
    case 'rating':
      return <>{data ? <LikeTable /> : <DislikeTable />}</>
    case 'parameter':
      if (row.type === 'chip')
        return <Chip size="small" label={row.value.value.name} sx={{ backgroundColor: '#D1FAE5', color: '#065F46' }} />
      if (row.type === 'string') {
        return (
          <Typography variant="subtitle2" sx={{ color: '#111827' }} align="center">
            {row.value.value} {row.code === 'PP' && '€'}
          </Typography>
        )
      }
      if (row.type === 'stringPer') {
        return (
          <Typography variant="subtitle2" sx={{ color: '#111827' }} align="center">
            {row.value.value} %
          </Typography>
        )
      }
      if (row.type === 'timeline1') {
        const values = Object.entries(row.value)
        return (
          <>
            {values.map((line: any, idx: number) => (
              <Typography key={idx} variant="subtitle2" align="center">
                {line[0]} ({parameterToTime(line[1])})
              </Typography>
            ))}
          </>
        )
      }
      if (row.type === 'timeline2') {
        const values = Object.entries(row.value)
        return (
          <>
            {values.map((line: any, idx: number) => (
              <Typography key={idx} variant="subtitle2" align="center">
                {line[0]} ({line[1].value}minutes)
              </Typography>
            ))}
          </>
        )
      }
      return <></>
    case 'numberRestaurants':
      return <Typography variant="body1">{row.group ? row.group.length || 0 : 0}</Typography>
    case 'status':
      if (row.status === 'ACTIVE')
        return <Chip size="small" label={labels.es.active} sx={{ backgroundColor: green[100], color: green[800] }} />
      if (row.status === 'DISABLED')
        return <Chip size="small" label={labels.es.disabled} sx={{ backgroundColor: red[100], color: red[800] }} />
      return <></>

    case 'typeBooking':
      // if(row.campaign){

      return (
        <Typography variant="body1" sx={{ color: '#6B7280' }}>
          {row.campaign && row.campaign.name
            ? row.campaign.name
            : row.incident && row.incident.name
            ? row.incident.name
            : 'Patología Común'}
        </Typography>
      )

    case 'typeIssue':
      // if(row.campaign){

      return (
        <Typography variant="body1" sx={{ color: '#6B7280' }}>
          {row.cancelationReason ? row.cancelationReason : row.modifyReason ? row.modifyReason : ''}
        </Typography>
      )

    case 'idCampaign':
      // if(row.campaign){
      return (
        <Typography variant="body1" sx={{ color: '#6B7280' }}>
          {data && data.name ? 'CAM' + data.name : 'CAM' + data}
        </Typography>
      )
    case 'idIncident':
      // if(row.campaign){
      return (
        <Typography variant="body1" sx={{ color: '#6B7280' }}>
          {data && data.name ? 'INC' + data.name : 'INC' + data}
        </Typography>
      )
    case 'lastnames':
      return (
        <Typography variant="body1" sx={{ color: '#6B7280', textTransform: 'capitalize' }}>
          {row && row.lastname2 ? row.lastname.toLowerCase() + ' ' + row.lastname2.toLowerCase() : row.lastname.toLowerCase()}
        </Typography>
      )
    default:
      return <></>
    // }else if(row.incident){
    //    return (
    //      <Typography variant="body1" sx={{ color: '#6B7280' }}>
    //        {data && data.name ? data.name : data}
    //      </Typography>
    //    )
    // }
  }
}
