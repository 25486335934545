import React, { FC, useContext } from 'react'
import { BrowserRouter, Route, Switch, RouteComponentProps, Redirect } from 'react-router-dom'
import LayoutAdmin from 'src/components/Layout/LayoutAdmin'
// import LayoutRestaurants from 'src/components/Layout/LayoutRestaurants'
import Welcome from 'src/pages/Welcome'
import adminRoutes from './AdminRoutes'
// import restaurantRoutes from './RestaurantRoutes'
import { primaryTheme } from 'src/styles/theme'
import { ThemeProvider } from '@mui/material/styles'
import { AuthContext } from 'src/context/auth/authContext'
import { AlertContext } from 'src/context/alert/alertContext'
import { SimpleDialog } from 'src/components/Modals/Dialog'
import { Alert, Snackbar } from '@mui/material'
import LoadingApp from 'src/components/LoadingApp'
import ScrollToTop from 'src/utils/scrollToTop'
import BookingSign from 'src/pages/AdminPlatform/Bookings/BookingSign'

const AppRouter: FC = () => {
  const { user, userData, initLoading } = useContext(AuthContext)
  const { alert, alertState, hideAlert, snackbar } = useContext(AlertContext)

  const handleClose = () => {
    hideAlert()
  }

  const hasAccess = (roles: Array<string>) => {
    const { role } = userData

    if (roles.includes(role.id)) return true
    else return false
  }

  if (initLoading) return <LoadingApp />

  return (
    <ThemeProvider theme={primaryTheme()}>
      <div>
        {user.user && alert && (
          <SimpleDialog
            open={alert}
            handleClose={handleClose}
            title={alertState.alertTitle as string}
            text={alertState.alertMsg as string}
            category={alertState.category as string}
          />
        )}
        {snackbar && (
          <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={snackbar}>
            <Alert
              sx={{ backgroundColor: alertState?.snackbarType === 'error' ? '#efbab7' : '#e5f2fa' }}
              severity={alertState?.snackbarType || 'success'}
            >
              {alertState.snackbarText}
            </Alert>
          </Snackbar>
        )}

        <BrowserRouter>
          <ScrollToTop />

          <Switch>
            <Route path="/firma/:idCita" exact component={BookingSign} />
            {user && user.user && userData ? (
              <LayoutAdmin>
                {/* AVOID RE RENDERS */}

                {/* <Route exact path="/" render={() => <Redirect to="/" />} /> */}
                {adminRoutes.map((route, index) => {
                  return (
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      render={(props: RouteComponentProps) =>
                        hasAccess(route.roles) ? <route.component name={route.name} {...props} {...route.props} /> : <Redirect to="/" />
                      }
                    />
                  )
                })}
                <Route exact path="/404" render={() => <h1>Not found!</h1>} />
                {/* <Redirect to="/calendarios" /> */}
              </LayoutAdmin>
            ) : (
              <>
                <Redirect to="/" />
                <Welcome />
              </>
            )}
          </Switch>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  )
}

export default AppRouter
