type dataObject = Record<string, string>

export const stores: dataObject[] = [
  { id: 'RFmfWRUGtLQzCZooCBCG', name: 'Obramat Alcalá de Guadaíra' },
  { id: 'YFpRnyrT61hKK4jcng6z', name: 'Obramat Alcobendas' },
  { id: 'PElwMA5nxCovViVeR0k3', name: 'Obramat Alcorcón' },
  { id: 'f7no0RsgjyyzkQifOzfU', name: 'Obramat Alicante' },
  { id: 'fxSOu4w4bmZ0Xe5AWer4', name: 'Obramat Badalona' },
  { id: 'cIyiw6XX6kZc7hMWdasw', name: 'Obramat Bormujos' },
  { id: 'sdt1f1yjU2RYfKOpYTVD', name: 'Obramat Burgos' },
  { id: 'NKQmJpUqe4UQR9drpK3u', name: 'Obramat Castellón' },
  { id: '31bV3HgkVPAdbwyJHyC5', name: 'Obramat Cordoba' },
  { id: 'DbL83opQ67QDf4jaqncp', name: 'Obramat Usera' },
  { id: 'nrXT2uKNrOEdWZOaRxjc', name: 'Obramat Finestrat' },
  { id: 'mq5lBK20WQxAEIT34iO9', name: 'Obramat Galdakao' },
  { id: '52VzLnqCxSXwhev0t1Gv', name: 'Obramat Hospitalet' },
  { id: '3rCmusLZdNvlWjpuoMBn', name: 'Obramat Jinámar' },
  { id: 'w6gUwaOLAoqIeZjO24ev', name: 'Obramat Leganés' },
  { id: 'zlnh150MVxxj3dNHmtZE', name: 'Obramat Lleida' },
  { id: 't0Sh9LDgsPL9mZhfY2jm', name: 'Obramat Los Barrios' },
  { id: 'vQKXvRLoVwHHrw384r3w', name: 'Obramat Lugo' },
  { id: '1SbIEXokTudIvhdX6yWC', name: 'Obramat Majadahonda' },
  { id: 'NMjZLxGXIIH3nPfvj0W5', name: 'Obramat Málaga' },
  { id: 'QS7IzxIOIZkeOaZ2AWzO', name: 'Obramat Massanassa' },
  { id: 'SU437fOgrd4KauKcIrnK', name: 'Obramat Murcia' },
  { id: 'PoSBlDu4vOSr5cjyHSS5', name: 'Obramat Pamplona' },
  { id: 'aY62jvAfSpmRNyLGO9VP', name: 'Obramat Palma' },
  { id: 'Oq7eBV3kG5Uv4C10kR5b', name: 'Obramat Rivas' },
  { id: 'x0p4Fzb9D4AohVdCuy9E', name: 'Obramat Salamanca' },
  { id: 'rQJJfVuvhbw2TZPnnTXf', name: 'Obramat Santander' },
  { id: 'N1Rh6Y5Eq0LUwX8dfhGn', name: 'Obramat Sabadell' },
  { id: 'bpFyVanglt3xOb11dk8P', name: 'Obramat Sestao' },
  { id: 'UZpXnvU6IzKRmTkU3EFY', name: 'Obramat Tarragona' },
  { id: 'KTofRHax9la7t9kQFbIX', name: 'Obramat Terrassa' },
  { id: 'TQZcMFyRdlcCB4LjxNHp', name: 'Obramat Siero' },
  { id: 'cOI75AWnYl50BITa7Twq', name: 'Obramat Santiago' },
  { id: 'yUNfafwNwix5ixFHCYgn', name: 'Obramat Vícar' },
  { id: '1myosgKfcuIKtlPlxZf6', name: 'Obramat Valladolid' },
  { id: 'epfvwL6ELak7cFD1Csjt', name: 'Obramat Vilanova' },
  { id: '5a78S3NUf8fMqppaGPwH', name: 'Obramat Zaragoza' },
  { id: 'k6mzslWr9L19VjEov4Ks', name: 'Servicios Internos' }
]

export const storesObj: Record<string, dataObject> = {
  'ALCALA DE GUADAIRA': { id: 'RFmfWRUGtLQzCZooCBCG', name: 'Obramat Alcalá de Guadaíra' },
  ALCOBENDAS: { id: 'YFpRnyrT61hKK4jcng6z', name: 'Obramat Alcobendas' },
  ALCORCON: { id: 'PElwMA5nxCovViVeR0k3', name: 'Obramat Alcorcón' },
  ALICANTE: { id: 'f7no0RsgjyyzkQifOzfU', name: 'Obramat Alicante' },
  BADALONA: { id: 'fxSOu4w4bmZ0Xe5AWer4', name: 'Obramat Badalona' },
  BORMUJOS: { id: 'cIyiw6XX6kZc7hMWdasw', name: 'Obramat Bormujos' },
  BURGOS: { id: 'sdt1f1yjU2RYfKOpYTVD', name: 'Obramat Burgos' },
  CASTELLON: { id: 'NKQmJpUqe4UQR9drpK3u', name: 'Obramat Castellón' },
  CORDOBA: { id: '31bV3HgkVPAdbwyJHyC5', name: 'Obramat Cordoba' },
  'MADRID USERA': { id: 'DbL83opQ67QDf4jaqncp', name: 'Obramat Usera' },
  FINESTRAT: { id: 'nrXT2uKNrOEdWZOaRxjc', name: 'Obramat Finestrat' },
  GALDAKAO: { id: 'mq5lBK20WQxAEIT34iO9', name: 'Obramat Galdakao' },
  HOSPITALET: { id: '52VzLnqCxSXwhev0t1Gv', name: 'Obramat Hospitalet' },
  JINAMAR: { id: '3rCmusLZdNvlWjpuoMBn', name: 'Obramat Jinámar' },
  LEGANES: { id: 'w6gUwaOLAoqIeZjO24ev', name: 'Obramat Leganés' },
  LLEIDA: { id: 'zlnh150MVxxj3dNHmtZE', name: 'Obramat Lleida' },
  'LOS BARRIOS': { id: 't0Sh9LDgsPL9mZhfY2jm', name: 'Obramat Los Barrios' },
  LUGO: { id: 'vQKXvRLoVwHHrw384r3w', name: 'Obramat Lugo' },
  MAJADAHONDA: { id: '1SbIEXokTudIvhdX6yWC', name: 'Obramat Majadahonda' },
  MALAGA: { id: 'NMjZLxGXIIH3nPfvj0W5', name: 'Obramat Málaga' },
  MASSANASSA: { id: 'QS7IzxIOIZkeOaZ2AWzO', name: 'Obramat Massanassa' },
  MURCIA: { id: 'SU437fOgrd4KauKcIrnK', name: 'Obramat Murcia' },
  PAMPLONA: { id: 'PoSBlDu4vOSr5cjyHSS5', name: 'Obramat Pamplona' },
  PALMA: { id: 'aY62jvAfSpmRNyLGO9VP', name: 'Obramat Palma' },
  RIVAS: { id: 'Oq7eBV3kG5Uv4C10kR5b', name: 'Obramat Rivas' },
  SALAMANCA: { id: 'x0p4Fzb9D4AohVdCuy9E', name: 'Obramat Salamanca' },
  SANTANDER: { id: 'rQJJfVuvhbw2TZPnnTXf', name: 'Obramat Santander' },
  SABADELL: { id: 'N1Rh6Y5Eq0LUwX8dfhGn', name: 'Obramat Sabadell' },
  SESTAO: { id: 'bpFyVanglt3xOb11dk8P', name: 'Obramat Sestao' },
  TARRAGONA: { id: 'UZpXnvU6IzKRmTkU3EFY', name: 'Obramat Tarragona' },
  TERRASSA: { id: 'KTofRHax9la7t9kQFbIX', name: 'Obramat Terrassa' },
  OVIEDO: { id: 'TQZcMFyRdlcCB4LjxNHp', name: 'Obramat Siero' },
  SANTIAGO: { id: 'cOI75AWnYl50BITa7Twq', name: 'Obramat Santiago' },
  ALMERIA: { id: 'yUNfafwNwix5ixFHCYgn', name: 'Obramat Vícar' },
  VALLADOLID: { id: '1myosgKfcuIKtlPlxZf6', name: 'Obramat Valladolid' },
  VILANOVA: { id: 'epfvwL6ELak7cFD1Csjt', name: 'Obramat Vilanova' },
  ZARAGOZA: { id: '5a78S3NUf8fMqppaGPwH', name: 'Obramat Zaragoza' },
  'SERVICIOS INTERNOS': { id: 'fdVsAM25I4Blx1Gk0gpf', name: 'Servicios Internos' }
}

export const sectors: dataObject[] = [
  { id: '9wD0qSafs0pBMdSBhyyJ', name: 'Almacenes-Organización' },
  { id: 'XKCkhiwAXhaNnCQStirJ', name: 'Bricolaje' },
  { id: 'kH1owkBpvRb8BSSlml0E', name: 'Cajas' },
  { id: 'QGjl3qWqsGE4WeCVPVef', name: 'Canal Pro' },
  { id: 'VVM2fSrTJ5is5kpFKwlZ', name: 'Cerámica' },
  { id: 'sUnnsIaHvjRALbmR3rPN', name: 'Codir' },
  { id: 'H2MzQZfWAspjfSXbnmyg', name: 'Control Gestión' },
  { id: 'EWoogPS3hjzvoGSOR49e', name: 'Digital Data Tech' },
  { id: 'oBZ80mRuwv1rJSkU7hne', name: 'Dpto. Cadena de Suministro' },
  { id: 'PSGFCUePN67kyUwSQxIB', name: 'Dpto. Compras' },
  { id: 'au6QUGjUd7L9ZJi2zpEU', name: 'Dpto. Financiero' },
  { id: 'P3H84DJhBeWKUutleLpS', name: 'Dpto. Seguridad y PRL' },
  { id: '1pvcxyNGGoNDxD1ZWvLZ', name: 'Dpto. Recursos Humanos' },
  { id: 'cfBA9LKNh8junzKD20yE', name: 'Dpto. Jurídico' },
  { id: 'Kqbf6zvb4yjxWeeKPNJc', name: 'Dpto. Marketing' },
  { id: 'Y1zA9JdXY6c3OcAf8r2f', name: 'Dpto. Innovación' },
  { id: 'GmjBlB08a90pizfg5IsA', name: 'Dpto. Expansión' },
  { id: 'MQeLFadhUVGlptCRfL9e', name: 'Electricidad' },
  { id: 'H2Rqqr8k6mPFqUaQttiG', name: 'Ferretería' },
  { id: '7xWJZbtbVwTePpTt44ia', name: 'Fontanería' },
  { id: 'S0cH2x7DCrlLWyHkbMuM', name: 'Gran Obra' },
  { id: 'WG866trq2pXZqTt7uT6m', name: 'Herramientas' },
  { id: 'smA5rPnhv0csOsn7qwF2', name: 'Iluminación' },
  { id: 'f2HCGFmUzZlbm8udiKS2', name: 'Informatica' },
  { id: 'vS9rwhyJ9IPLO1U199Hc', name: 'Jardinería' },
  { id: 'pddMbo5vlLJWHEYSAhL6', name: 'Logística' },
  { id: 'FCkdSAENU5Q2i6A8tKNo', name: 'Madera' },
  { id: 'vHOMpNkQ6LkoiGkDlxpm', name: 'Materiales' },
  { id: 'gK4q0U8c9Qv2Wb1S2SsL', name: 'Obras' },
  { id: 'MoirkMHAM0YrX8gqR2X5', name: 'Pintura' },
  { id: 'ZNulyA0KXkK50GhYwaJt', name: 'Sanitario' }
]

export const sectorsObj: Record<string, dataObject[]> = {
  ACABADO: [{ id: 'g6FGqTU21xOHiD5qSJ2d', name: 'Acabado' }],
  ACONDICIONAMIENTO: [{ id: 'u1HCiaNvn1MZKQTK6IH3', name: 'Acondicionamiento' }],
  'Almacenes-Organizacion': [{ id: '9wD0qSafs0pBMdSBhyyJ', name: 'Almacenes-Organización' }],
  CAJAS: [{ id: 'kH1owkBpvRb8BSSlml0E', name: 'Cajas' }],
  'CANAL PRO': [{ id: 'QGjl3qWqsGE4WeCVPVef', name: 'Canal Pro' }],
  DIRECCION: [{ id: 'r72tlzDBoVk7yXpvb0Sl', name: 'Dirección' }],
  CONTABILIDAD: [{ id: '5SPptrdhqEWmGcbfR6eA', name: 'Contabilidad' }],
  'CONTROL GESTION': [{ id: 'H2MzQZfWAspjfSXbnmyg', name: 'Control Gestión' }],
  'CADENA SUMINISTRO': [{ id: 'oBZ80mRuwv1rJSkU7hne', name: 'Dpto. Cadena de Suministro' }],
  COMPRAS: [{ id: 'PSGFCUePN67kyUwSQxIB', name: 'Dpto. Compras' }],
  'DIRECTOR FINANCIERO': [{ id: 'au6QUGjUd7L9ZJi2zpEU', name: 'Dpto. Financiero' }],
  'EQUIPAMIENTO TECNICO': [{ id: 'z5JIO23XUADv1vl2GGLt', name: 'Equipamiento Técnico' }],
  RRHH: [{ id: '1pvcxyNGGoNDxD1ZWvLZ', name: 'Dpto. Recursos Humanos' }],
  JURIDICO: [{ id: 'cfBA9LKNh8junzKD20yE', name: 'Dpto. Jurídico' }],
  MARKETING: [{ id: 'Kqbf6zvb4yjxWeeKPNJc', name: 'Dpto. Marketing' }],
  INNOVACION: [{ id: 'Y1zA9JdXY6c3OcAf8r2f', name: 'Dpto. Innovación' }],
  ELECTRICIDAD: [{ id: 'MQeLFadhUVGlptCRfL9e', name: 'Electricidad' }],
  FONTANERIA: [{ id: '7xWJZbtbVwTePpTt44ia', name: 'Fontanería' }],
  'GRAN OBRA': [{ id: 'S0cH2x7DCrlLWyHkbMuM', name: 'Gran Obra' }],
  HERRAMIENTAS: [{ id: 'WG866trq2pXZqTt7uT6m', name: 'Herramientas' }],
  INFORMATICA: [{ id: 'f2HCGFmUzZlbm8udiKS2', name: 'Informatica' }],
  LOGISTICA: [{ id: 'pddMbo5vlLJWHEYSAhL6', name: 'Logística' }],
  MADERA: [{ id: 'FCkdSAENU5Q2i6A8tKNo', name: 'Madera' }],
  MATERIALES: [{ id: 'vHOMpNkQ6LkoiGkDlxpm', name: 'Materiales' }],
  OBRAS: [{ id: 'gK4q0U8c9Qv2Wb1S2SsL', name: 'Obras' }],
  SANITARIO: [{ id: 'ZNulyA0KXkK50GhYwaJt', name: 'Sanitario' }],
  'OBM - AREA FINANCE AND PERFORMANCE': [{ id: 'f2HCGFmUzZlbm8udiKS2', name: 'Informatica' }]
}

// { id: 'VVM2fSrTJ5is5kpFKwlZ', name: 'Cerámica' },
// { id: 'MoirkMHAM0YrX8gqR2X5', name: 'Pintura' },
// { id: 'vS9rwhyJ9IPLO1U199Hc', name: 'Jardinería' },
// { id: 'smA5rPnhv0csOsn7qwF2', name: 'Iluminación' },
// { id: 'H2Rqqr8k6mPFqUaQttiG', name: 'Ferretería' },

export const roles: dataObject[] = [
  { id: 'role5', name: 'Abogado/a Relaciones Laborales' },
  { id: 'role3', name: 'Administrativo/a' },
  { id: 'role5', name: 'Administrativo/a Obras' },
  { id: 'role5', name: 'Administrativo/a Tesorería' },
  { id: 'role5', name: 'Aprovisionador Cds' },
  { id: 'role3', name: 'Asist. Dirección' },
  { id: 'role3', name: 'Asist. Producto' },
  { id: 'role6', name: 'Cajero/a' },
  { id: 'role5', name: 'Cajero/a Principal' },
  { id: 'role5', name: 'Canal Profesional' },
  { id: 'role5', name: 'Chief Technology Officer' },
  { id: 'role5', name: 'Cloud Operations Engineer' },
  { id: 'role5', name: 'Control de Gestión Operacional' },
  { id: 'role5', name: 'Controlador Interno' },
  { id: 'role5', name: 'Controler Gestión' },
  { id: 'role5', name: 'Coord. Logístico' },
  { id: 'role5', name: 'Coordinador/a Proyectos Cds' },
  { id: 'role5', name: 'Coor. Cad Sumi Ola' },
  { id: 'role5', name: 'Coor. Operac. Cad Sumi' },
  { id: 'role5', name: 'C. Gest. Operacional' },
  { id: 'role5', name: 'Data Analyst' },
  { id: 'role5', name: 'Data Architect' },
  { id: 'role5', name: 'Digital Data Analyst' },
  { id: 'role5', name: 'Digital Data Engineer' },
  { id: 'role4', name: 'Director/a' },
  { id: 'role5', name: 'Dtor/a Desarrollo' },
  { id: 'role5', name: 'E-Merchan' },
  { id: 'role5', name: 'Especialista Seo' },
  { id: 'role5', name: 'Gerente del Equipo de Transporte B2B' },
  { id: 'role5', name: 'Gerente del Equipo de Transporte B2C' },
  { id: 'role5', name: 'Head Of Product Fico&Hr' },
  { id: 'role5', name: 'Head Of Product Omnicommerce' },
  { id: 'role5', name: 'Head Of Product Supply & Offre' },
  { id: 'role1', name: 'SuperUser' },
  { id: 'role5', name: 'Jefe de Auditoría' },
  { id: 'role3', name: 'Jefe/a de Mercado' },
  { id: 'role3', name: 'Jefe/a de Producto' },
  { id: 'role5', name: 'Jefe/a de Sector' },
  { id: 'role5', name: 'Jefe/a Proyec Formac' },
  { id: 'role5', name: 'Jefe/a Proyecto Eficiencia Financiera' },
  { id: 'role5', name: 'Jefe/a Proyecto Mobi' },
  { id: 'role5', name: 'Jefe/a Proyecto Selección' },
  { id: 'role3', name: 'Líder' },
  { id: 'role5', name: 'Lider Asist Prod' },
  { id: 'role5', name: 'Lider de Organización y Optimización de Compras' },
  { id: 'role3', name: 'Mando SS.II' },
  { id: 'role5', name: 'Network Engineer' },
  { id: 'role5', name: 'Operations Service Manager' },
  { id: 'role5', name: 'People Experience Specialist' },
  { id: 'role5', name: 'Planner Cds' },
  { id: 'role5', name: 'Product Manager Fico&Hr' },
  { id: 'role5', name: 'Product Manager Omnicommerce' },
  { id: 'role5', name: 'Product Manager Supply & Offre' },
  { id: 'role5', name: 'Project Manager Transversal' },
  { id: 'role5', name: 'Quality Engineer' },
  { id: 'role5', name: 'Recepcionista' },
  { id: 'role6', name: 'Recepcionista Logística' },
  { id: 'role5', name: 'Resp. Comunicación' },
  { id: 'role5', name: 'Resp. Conta Gral/Rep' },
  { id: 'role5', name: 'Resp. Conta Proveedo' },
  { id: 'role5', name: 'Resp. Contabilidad' },
  { id: 'role5', name: 'Resp. Control Interno y Compliance Officer' },
  { id: 'role5', name: 'Resp. Expansión' },
  { id: 'role5', name: 'Resp. Formación' },
  { id: 'role5', name: 'Resp. Marketing/Com' },
  { id: 'role5', name: 'Resp. Merchandising' },
  { id: 'role5', name: 'Resp. Obras y Mobili' },
  { id: 'role5', name: 'Resp. Regional RRHH' },
  { id: 'role5', name: 'Resp. RR.LL. AA.PP.' },
  { id: 'role5', name: 'Resp. Selección' },
  { id: 'role5', name: 'Responsable Cajas' },
  { id: 'role5', name: 'Responsable Comercial' },
  { id: 'role5', name: 'Responsable Jurídico' },
  { id: 'role5', name: 'Responsable Logística' },
  { id: 'role5', name: 'Responsable PRL' },
  { id: 'role5', name: 'Responsable Red Logística' },
  { id: 'role3', name: 'Responsable SS.II' },
  { id: 'role5', name: 'Responsable de Planning Cds' },
  { id: 'role5', name: 'Tec. Comunicación' },
  { id: 'role5', name: 'Tec. Contabilidad' },
  { id: 'role5', name: 'Tec. Contb-Gral Ac Im' },
  { id: 'role5', name: 'Tec. Impacto Positivo' },
  { id: 'role5', name: 'Tec. Jurídico' },
  { id: 'role5', name: 'Tec. Merchandising' },
  { id: 'role5', name: 'Tec. PRL' },
  { id: 'role5', name: 'Tec. RRLL' },
  { id: 'role5', name: 'Tec. Selección' },
  { id: 'role5', name: 'Tec. Transporte' },
  { id: 'role5', name: 'Tec. de Formación' },
  { id: 'role5', name: 'Tec. de Sistemas' },
  { id: 'role5', name: 'Tec Comunicación Omnicanal' },
  { id: 'role5', name: 'Tec Compra Indirectas' },
  { id: 'role3', name: 'Técnico/a' },
  { id: 'role5', name: 'Técnico/a Concepción Proyectos' },
  { id: 'role5', name: 'Técnico/a Estudios de Mercado' },
  { id: 'role5', name: 'Técnico/a Oficina Técnica CC' },
  { id: 'role5', name: 'Técnico/a Operaciones Cds' },
  { id: 'role5', name: 'Técnico Ola Cds' },
  { id: 'role6', name: 'Vendedor/a' }
]

export const rolesObj: Record<string, dataObject> = {
  'ABOGADO-A RELACIONES LABORALES': { id: 'role5', name: 'Abogado/a Relaciones Laborales' },
  'ADMINISTRATIVO-A': { id: 'role3', name: 'Administrativo/a' },
  'ADMINISTRATIVO/A OBRAS': { id: 'role5', name: 'Administrativo/a Obras' },
  'ADMINISTRATIVO-A TESORERIA': { id: 'role5', name: 'Administrativo/a Tesorería' },
  'APROVISIONADOR CDS': { id: 'role5', name: 'Aprovisionador Cds' },
  'ASISTENTE DIRECCIÓN': { id: 'role3', name: 'Asist. Dirección' },
  'ASISTENTE PRODUCTO': { id: 'role3', name: 'Asist. Producto' },
  'CAJERO - A': { id: 'role6', name: 'Cajero/a' },
  'CAJERO - A PRINCIPAL': { id: 'role5', name: 'Cajero/a Principal' },
  'CHIEF TECHNOLOGY OFFICER': { id: 'role5', name: 'Chief Technology Officer' },
  'CLOUD OPERATIONS ENGINEER': { id: 'role5', name: 'Cloud Operations Engineer' },
  'C.GEST.OPERACIONAL': { id: 'role5', name: 'C. Gest. Operacional' },
  'CONTROL DE GESTION OPERACIONAL': { id: 'role5', name: 'Control de Gestión Operacional' }, // CREAR
  'CONTROLADOR INTERNO': { id: 'role5', name: 'Controlador Interno' },
  'CONTROLER GESTION': { id: 'role5', name: 'Controler Gestión' },
  'COORD. LOGISTICO': { id: 'role5', name: 'Coord. Logístico' },
  'COORDINADOR/A PROYECTOS CDS': { id: 'role5', name: 'Coordinador/a Proyectos Cds' },
  'COOR. CAD SUMI OLA': { id: 'role5', name: 'Coor. Cad Sumi Ola' },
  'COOR OPERAC CAD SUMI': { id: 'role5', name: 'Coor. Operac. Cad Sumi' },
  'DATA ANALYST': { id: 'role5', name: 'Data Analyst' },
  'DATA ARCHITECT': { id: 'role5', name: 'Data Architect' },
  'DIGITAL DATA ANALYST': { id: 'role5', name: 'Digital Data Analyst' },
  'DIGITAL DATA ENGINEER': { id: 'role5', name: 'Digital Data Engineer' },
  'DIRECTOR-A RRHH': { id: 'role4', name: 'Director-a RRHH' },
  'DIRECTOR-A REGIONAL': { id: 'role4', name: 'Director-a Regional' },
  'DIRECTOR-A ALMACEN': { id: 'role4', name: 'Director-a Almacen' },
  'DIRECTOR GENERAL': { id: 'role4', name: 'Director General' },
  'DIRECTOR-A COMPRAS': { id: 'role4', name: 'Director-a Compras' },
  'DIRECTOR-A TECNICO-A': { id: 'role4', name: 'Director-a Tecnico-a' },
  'DIRECTOR-A CADENA SUMINISTRO': { id: 'role4', name: 'Director-a Cadena Suministro' },
  'DIRECTOR-A ORGANIZ SIST': { id: 'role4', name: 'Director-a Organiz Sist' },
  'DTOR-A FINANCIERO-A': { id: 'role4', name: 'Director-a Financiero-a' },
  'DTOR-A DPT JURIDICO': { id: 'role4', name: 'Dtor-a Dpt Juridico' },
  'DTOR-A DESARROLLO': { id: 'role5', name: 'Dtor/a Desarrollo' },
  'E-MERCHAN': { id: 'role5', name: 'E-Merchan' },
  'ESPECIALISTA SEO': { id: 'role5', name: 'Especialista Seo' },
  'GERENTE DEL EQUIPO DE TRANSPORTE B2B': { id: 'role5', name: 'Gerente del Equipo de Transporte B2B' },
  'GERENTE DEL EQUIPO DE TRANSPORTE B2C': { id: 'role5', name: 'Gerente del Equipo de Transporte B2C' },
  'HEAD OF PRODUCT FICO&HR': { id: 'role5', name: 'Head Of Product Fico&Hr' },
  'HEAD OF PRODUCT OMNICOMMERCE': { id: 'role5', name: 'Head Of Product Omnicommerce' },
  'HEAD OF PRODUCT SUPPLY & OFFRE': { id: 'role5', name: 'Head Of Product Supply & Offre' },
  'Jefe de Auditoría': { id: 'role5', name: 'Jefe de Auditoría' },
  'JEFE-A DE MERCADO': { id: 'role3', name: 'Jefe/a de Mercado' },
  'JEFE-A DE PRODUCTO': { id: 'role3', name: 'Jefe/a de Producto' },
  'JEFE-A DE SECTOR': { id: 'role5', name: 'Jefe/a de Sector' },
  'JEFE-A PROYEC FORMAC': { id: 'role5', name: 'Jefe/a Proyec Formac' }, // PONER FORMACION
  'JEFE/A DE PROYECTO EFICIENCIA FINANCIERA': { id: 'role5', name: 'Jefe/a Proyecto Eficiencia Financiera' },
  'JEFE-A PROYECTO MOBI': { id: 'role5', name: 'Jefe/a Proyecto Mobi' },
  'JEFE-A PROYECTO SELECCIÓN': { id: 'role5', name: 'Jefe/a Proyecto Selección' },
  'LIDER ASIST PROD': { id: 'role5', name: 'Lider Asist Prod' },
  'LIDER DE ORGANIZACION Y OPTIMIZACION DE COMPRAS': { id: 'role5', name: 'Lider de Organización y Optimización de Compras' },
  'NETWORK ENGINEER': { id: 'role5', name: 'Network Engineer"' },
  'OPERATIONS SERVICE MANAGER': { id: 'role5', name: 'Operations Service Manager' },
  'PEOPLE EXPERIENCE SPECIALIST': { id: 'role5', name: 'People Experience Specialist' },
  'PLANNER CDS': { id: 'role5', name: 'Planner Cds' },
  'PRODUCT MANAGER FICO&HR': { id: 'role5', name: 'Product Manager Fico&Hr' },
  'PRODUCT MANAGER OMNICOMMERCE': { id: 'role5', name: 'Product Manager Omnicommerce' },
  'PRODUCT MANAGER SUPPLY & OFFRE': { id: 'role5', name: 'Product Manager Supply & Offre' },
  'PROJECT MANAGER TRANSVERSAL': { id: 'role5', name: 'Project Manager Transversal' },
  'QUALITY ENGINEER': { id: 'role5', name: 'Quality Engineer' },
  RECEPCIONISTA: { id: 'role5', name: 'Recepcionista' },
  'RESP. COMUNICACION': { id: 'role5', name: 'Resp. Comunicación' },
  'RESP. CONTA GRAL/REP': { id: 'role5', name: 'Resp. Conta Gral/Rep' },
  'RESP. CONTA PROVEEDO': { id: 'role5', name: 'Resp. Conta Proveedo' },
  'RESP. CONTABILIDAD': { id: 'role5', name: 'Resp. Contabilidad' },
  'RESP. CONTROL INTERNO Y COMPLIANCE OFFICER': { id: 'role5', name: 'Resp. Control Interno y Compliance Officer' },
  'RESP. EXPANSION': { id: 'role5', name: 'Resp. Expansión' },
  'RESP. FORMACION': { id: 'role5', name: 'Resp. Formación' },
  'RESP. MARKETING/ COM': { id: 'role5', name: 'Resp. Marketing/Com' },
  'RESP. MERCHANDISING': { id: 'role5', name: 'Resp. Merchandising' },
  'RESP. OBRAS y MOBILI': { id: 'role5', name: 'Resp. Obras y Mobili' },
  'RESP REGIONAL RRHH': { id: 'role5', name: 'Resp. Regional RRHH' },
  'RESP. RR.LL. AA.PP.': { id: 'role5', name: 'Resp. RR.LL. AA.PP.' },
  'RESP. SELECCION': { id: 'role5', name: 'Resp. Selección' },
  'RESPONSABLE DE CAJAS': { id: 'role5', name: 'Responsable Cajas' },
  'RESPONSABLE CIAL': { id: 'role5', name: 'Responsable Comercial' },
  'RESPONSABLE JURIDICO': { id: 'role5', name: 'Responsable Jurídico' },
  'RESPONSABLE LOGISTIC': { id: 'role5', name: 'Responsable Logística' },
  'RESPONSABLE DE PRL': { id: 'role5', name: 'Responsable PRL' },
  'RESPONSABLE RED LOGISTICA': { id: 'role5', name: 'Responsable Red Logística' },
  'RESPONSABLE RRHH': { id: 'role5', name: 'Responsable RRHH' },
  'RESPONSABLE DE PLANNING CDS': { id: 'role5', name: 'Responsable de Planning Cds' },
  'TEC. COMUNICACIÓN': { id: 'role5', name: 'Tec. Comunicación' },
  'TEC. CONTABILIDAD': { id: 'role5', name: 'Tec. Contabilidad' },
  'TÉC.CONTB-GRAL AC IM': { id: 'role5', name: 'Tec. Contb-Gral Ac Im' },
  'TEC. IMPACTO POSITIVO': { id: 'role5', name: 'Tec. Impacto Positivo' },
  'TEC. JURIDICO': { id: 'role5', name: 'Tec. Jurídico' },
  'TEC. MERCHANDISING': { id: 'role5', name: 'Tec. Merchandising' },
  'TEC. PRL': { id: 'role5', name: 'Tec. PRL' },
  'TEC. RRLL': { id: 'role5', name: 'Tec. RRLL' },
  'TEC. SELECCIÓN': { id: 'role5', name: 'Tec. Selección' },
  'TEC.TRANSPORTE': { id: 'role5', name: 'Tec. Transporte' },
  'TEC. DE FORMACION': { id: 'role5', name: 'Tec. de Formación' },
  'TEC. DE SISTEMAS': { id: 'role5', name: 'Tec. de Sistemas' },
  'TEC COMUNICACION OMNICANAL': { id: 'role5', name: 'Tec Comunicación Omnicanal' },
  'TEC COMPRAS INDIRECTAS': { id: 'role5', name: 'Tec Compra Indirectas' },
  'TÉCNICO/A CONCEPCIÓN PROYECTOS': { id: 'role5', name: 'Técnico/a Concepción Proyectos' },
  'TÉCNICO/A ESTUDIOS DE MERCADO': { id: 'role5', name: 'Técnico/a Estudios de Mercado' },
  'TECNICO/A OFICINA TECNICA CC': { id: 'role5', name: 'Técnico/a Oficina Técnica CC' },
  'TECNICO/A OPERACIONES CDS': { id: 'role5', name: 'Técnico/a Operaciones Cds' },
  'TÉCNICO OLA CDS': { id: 'role5', name: 'Técnico Ola Cds' },
  'VENDEDOR-A': { id: 'role6', name: 'Vendedor/a' }
}
