import React, { useContext, useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import { HeaderContext } from 'src/context/header/headerContext'
import { AuthContext } from 'src/context/auth/authContext'
import { saveAs } from 'file-saver'
import { Download } from '@mui/icons-material'
import CardAyuda from 'src/components/Cards/CardAyuda'
import { CardSummaryGrid } from 'src/components/Cards/cards.styles'
import firebase from 'src/firebase/firebase'
import { Container, MenuItem, Select, Grid, Box, IconButton, Divider, useTheme, LinearProgress } from '@mui/material'
import { showStoresInvoices, sendMailInvoicesOk } from 'src/utils/swal.utils'
import Swal from 'sweetalert2'

export default function Utilidades(): JSX.Element {
  const { showHeader } = useContext(HeaderContext)
  const theme = useTheme()
  const [loading, setLoading] = useState(false)
  const { userData } = useContext(AuthContext)
  const [month, setMonth] = useState(new Date().getMonth())
  const [year, setYear] = useState(new Date().getUTCFullYear())

  const Header = () => {
    return (
      <div className="flex-center ">
        <Typography variant="h3" component="h3">
          Exportar Datos
        </Typography>
        <Typography variant="subtitle2" component="h3"></Typography>
      </div>
    )
  }

  const handleChange = (event: any) => {
    // event.target.name === 'year' && setYear(event.target.value)
    event.target.name === 'month' && setMonth(event.target.value)
  }

  const handleChangeYear = (event: any) => {
    // event.target.name === 'year' && setYear(event.target.value)
    event.target.name === 'year' && setYear(event.target.value)
  }

  useEffect(() => {
    showHeader(Header)
  }, [])

  const callReport = async () => {
    setLoading(true)
    const downloadReport = firebase.functions.httpsCallable('generateReport')

    downloadReport(userData.storeId || null)
      .then((response) => {
        saveAs('data:application/pdf;base64,' + response.data, 'NoAsiste.xlsx')
        setLoading(false)
      })

      .catch((error) => {
        setLoading(false)
        console.log(error.message)
      })
  }

  const callMonthlyReport = async () => {
    setLoading(true)
    const downloadMonthlyReport = firebase.functions.httpsCallable('monthlyReportData')

    downloadMonthlyReport({ month, year, storeId: userData.storeId || null })
      .then((response) => {
        saveAs('data:application/pdf;base64,' + response.data, 'ReporteMensual.xlsx')
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.log(error.message)
      })
  }

  // const callExportUsersOidc = async () => {
  //   const downloadOidcUsers = firebase.functions.httpsCallable('exportUserList')

  //   downloadOidcUsers()
  //     .then((response) => {
  //       saveAs('data:application/pdf;base64,' + response.data, 'UsersOidc.xlsx')
  //       setLoading(false)
  //     })
  //     .catch((error) => {
  //       setLoading(false)
  //       console.log(error.message)
  //     })
  // }

  const callMonthlyReportAdmin = async () => {
    setLoading(true)
    const downloadMonthlyReport = firebase.functions.httpsCallable('monthlyReportAdmin')

    downloadMonthlyReport({ month, year })
      .then((response) => {
        saveAs('data:application/pdf;base64,' + response.data, 'ReporteMensualAdmin.xlsx')
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.log(error.message)
      })
  }

  const callAllNotCompleted = async () => {
    setLoading(true)
    const downloadFullNotCompletedReport = firebase.functions.httpsCallable('getUsersNotCompleting')

    downloadFullNotCompletedReport()
      .then((response) => {
        saveAs('data:application/pdf;base64,' + response.data, 'ReporteHistoricoNoCompletados.xlsx')
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.log(error.message)
      })
  }

  const callInvoicesReport = async (sendMail: boolean) => {
    setLoading(true)
    const invoicesReport = firebase.functions.httpsCallable('sendInvoices')

    invoicesReport(sendMail)
      .then((response) => {
        if (!sendMail) {
          Swal.fire(showStoresInvoices(theme.palette.primary.main, response.data))
        } else {
          Swal.fire(sendMailInvoicesOk(theme.palette.primary.main))
        }
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.log(error.message)
      })
  }

  return (
    <>
      {loading && <LinearProgress />}
      <Container sx={{ my: 3 }}>
        {/* {userData.role.id === "role1" && ( */}
        <Grid item xs={6} lg={3} style={{ marginBottom: 10, display: 'flex', alignItems: 'center', gap: '10px' }} spacing={1}>
          <label style={{ fontSize: 14 }}>Mes</label>
          <Select name={'month'} value={month} onChange={handleChange} size="small" fullWidth>
            <MenuItem value={0}>Enero</MenuItem>
            <MenuItem value={1}>Febrero</MenuItem>
            <MenuItem value={2}>Marzo</MenuItem>
            <MenuItem value={3}>Abril</MenuItem>
            <MenuItem value={4}>Mayo</MenuItem>
            <MenuItem value={5}>Junio</MenuItem>
            <MenuItem value={6}>Julio</MenuItem>
            <MenuItem value={7}>Agosto</MenuItem>
            <MenuItem value={8}>Septiembre</MenuItem>
            <MenuItem value={9}>Octubre</MenuItem>
            <MenuItem value={10}>Noviembre</MenuItem>
            <MenuItem value={11}>Diciembre</MenuItem>
          </Select>
          <label style={{ fontSize: 14 }}>Año</label>
          <Select name={'year'} value={year} onChange={handleChangeYear} size="small" fullWidth>
            <MenuItem value={2023}>2023</MenuItem>
            <MenuItem value={2024}>2024</MenuItem>
            <MenuItem value={2025}>2025</MenuItem>
          </Select>
        </Grid>
        {/* )} */}
        <Grid container spacing={3}>
          {/* <CardSummaryGrid item xs={12} md={4} onClick={() =>window.open('https://metaphysiotesting.web.app/getPendingBookings')}> */}
          <CardSummaryGrid item xs={12} md={4} onClick={() => callReport()}>
            <CardAyuda
              Icon={Download}
              text="Exporta un histórico de las citas en las que el colaborador no ha asistido"
              title="Citas sin asistencia"
            />
          </CardSummaryGrid>
          <CardSummaryGrid item xs={12} md={4} onClick={() => callMonthlyReport()}>
            <CardAyuda Icon={Download} text="Exporta un registro de las citas del mes actual y su estado" title="Resumen mensual" />
          </CardSummaryGrid>
          {userData.role.id === 'role1' && (
            <>
              <CardSummaryGrid item xs={12} md={4} onClick={() => callMonthlyReportAdmin()}>
                <CardAyuda
                  Icon={Download}
                  text="Exporta un registro de las citas del mes actual realizadas por tienda"
                  title="Resumen mensual(Solo admin)"
                />
              </CardSummaryGrid>
              <CardSummaryGrid item xs={12} md={4} onClick={() => callAllNotCompleted()}>
                <CardAyuda
                  Icon={Download}
                  text="Exporta un registro de las citas no completadas con un ranking de pacientes"
                  title="Registro histórico citas no completadas(Solo admin)"
                />
              </CardSummaryGrid>
              <CardSummaryGrid item xs={12} md={4} onClick={() => callInvoicesReport(false)}>
                <CardAyuda Icon={Download} text="Consultar previsión facturacion" title="Ver facturación(Solo admin)" />
              </CardSummaryGrid>
              <CardSummaryGrid item xs={12} md={4} onClick={() => callInvoicesReport(true)}>
                <CardAyuda Icon={Download} text="Enviar facturación a almacenes" title="Enviar facturación(Solo admin)" />
              </CardSummaryGrid>
            </>
          )}
        </Grid>
      </Container>
    </>
  )
}
