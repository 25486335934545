import React, { useState, useEffect, useContext } from 'react'
import Box from '@mui/material/Box'
import TextInput from 'src/components/Form/TextInput'
import Modal from 'src/components/Modals/Modal'
import FooterForm from 'src/components/Form/FooterForm'

import { useForm } from 'src/hooks/useForm'
import { AlertContext } from 'src/context/alert/alertContext'
import { AuthContext } from 'src/context/auth/authContext'
import { InfoContext } from 'src/context/info/infoContext'
import { labels } from 'src/labels/main_labels'
import { createIncident, updateIncident } from 'src/services/incidents.services'
import { getAllUsersByCompanyAndStore } from 'src/services/users.services'
import { getAllCompanyConfig } from 'src/services/companies.services'
import { getAllStoresByCompany } from 'src/services/stores.services'
import SelectDialog from 'src/components/Form/SelectDialog'
import ChipSelector from 'src/components/Form/ChipSelector'
import { AddEditProps } from 'src/types/pages.types'

const INITIAL_VALUES = {
  description: '',
  product: '',
  incidentId: '',
  daysSinceDayOff: '',
  why: '',
  when: '',
  whatHappened: ''
}

export default function IncidentsAddEdit({ dataToEdit, closeModal, openModal, onDelete, fetchData }: AddEditProps): JSX.Element {
  const editing = !!dataToEdit
  const { showSnackbar } = useContext(AlertContext)
  const { userData } = useContext(AuthContext)
  const { machines, processes, sectors, areas, pathologies, allCompanies, fetchPathologies } = useContext(InfoContext)
  const { handleChange, values, resetForm } = useForm(INITIAL_VALUES, dataToEdit)
  const [loading, setLoading] = useState(false)
  const [companyUsers, setCompanyUsers] = useState([])
  const [userResponsable, setUserResponsable] = useState<any | never>(null)
  const [injuredWorker, setInjuredWorker] = useState<any | never>(null)
  const [timeWindow, setTimeWindow] = useState<any | never>(null)
  const [processSelected, setProcessSelected] = useState<any | never>(null)
  const [machineSelected, setMachineSelected] = useState<any | never>(null)
  const [sectorSelected, setSectorSelected] = useState<any | never>(null)
  const [areaSelected, setAreaSelected] = useState<any | never>(null)
  const [whoParticipatedAnalyse, setWhoParticipatedAnalyse] = useState<any[] | never>([])
  const [testigos, setTestigos] = useState<any[] | never>([])
  const [affectedZone, setAffectedZone] = useState<any | never>(null)
  const [how, setHow] = useState<any | never>(null)
  const [allCompanyStores, setAllCompanyStores] = useState<any[]>([])
  const [store, setStore] = useState<any | never>(null)
  const [company, setCompany] = useState<any | never>(null)
  const [configCompanyAdmin, setConfigCompanyAdmin] = useState([])

  const resetComponent = () => {
    setLoading(false)
    resetForm()
    setUserResponsable(null)
    setInjuredWorker(null)
    setProcessSelected(null)
    setMachineSelected(null)
    setSectorSelected(null)
    setTimeWindow(null)
    setWhoParticipatedAnalyse([])
    setTestigos([])
    setAffectedZone(null)
    setHow(null)
    setStore(null)
    setCompany(null)
    setAllCompanyStores([])
    setConfigCompanyAdmin([])
    setAreaSelected(null)
  }

  useEffect(() => {
    if (dataToEdit) {
      if (dataToEdit.injuredWorker) setInjuredWorker(dataToEdit.injuredWorker)
      if (dataToEdit.userResponsable) setUserResponsable(dataToEdit.userResponsable)
      if (dataToEdit.process) setProcessSelected(dataToEdit.process)
      if (dataToEdit.machine) setMachineSelected(dataToEdit.machine)
      if (dataToEdit.sector) setSectorSelected(dataToEdit.sector)
      if (dataToEdit.timeWindow) setTimeWindow(dataToEdit.timeWindow)
      if (dataToEdit.testigos) setTestigos(dataToEdit.testigos)
      if (dataToEdit.whoParticipatedAnalyse) setWhoParticipatedAnalyse(dataToEdit.whoParticipatedAnalyse)
      if (dataToEdit.affectedZone) setAffectedZone(dataToEdit.affectedZone)
      if (dataToEdit.how) setHow(dataToEdit.how)
      if (dataToEdit.where) setAreaSelected(dataToEdit.where)
      if (dataToEdit.company) setCompany(dataToEdit.company)
      if (dataToEdit.store) setStore(dataToEdit.store)
    }

    return () => resetComponent()
  }, [dataToEdit])

  const loadUsers = async () => {
    if (userData && userData.companyId && userData.storeId) {
      const users = await getAllUsersByCompanyAndStore(userData.companyId, userData.storeId)
      if (users && users.length) setCompanyUsers(users)
    } else if (userData.role.id === 'role1' && company && store) {
      const users = await getAllUsersByCompanyAndStore(company.id, store.id)
      if (users && users.length) setCompanyUsers(users)
    }
  }

  const loadCompanyStores = async () => {
    if (userData.role.id !== 'role3') {
      const companyStores = await getAllStoresByCompany(company.id)

      if (companyStores && companyStores.length) setAllCompanyStores(companyStores)
    }
    if (userData.role.id === 'role3') {
      const companyStores = await getAllStoresByCompany(userData.companyId)

      if (companyStores && companyStores.length) setAllCompanyStores(companyStores)
    }
  }

  const loadCompanyConfig = async () => {
    const companyConfig = await getAllCompanyConfig(company.id)

    if (companyConfig && companyConfig.length) setConfigCompanyAdmin(companyConfig)
  }

  const loadAdminUsers = async () => {
    if (userData && userData.role.id === 'role1' && store && company) {
      const users = await getAllUsersByCompanyAndStore(company.id, store.id)
      if (users && users.length) {
        setCompanyUsers(users)
        if (!dataToEdit) setUserResponsable(null)
      } else {
        if (!dataToEdit) setUserResponsable(null)
        setCompanyUsers([])
      }
    } else if (userData && userData.role.id === 'role3' && store) {
      const users = await getAllUsersByCompanyAndStore(userData.companyId, store.id)
      if (users && users.length) {
        setCompanyUsers(users)
        if (!dataToEdit) setUserResponsable(null)
      } else {
        if (!dataToEdit) setUserResponsable(null)
        setCompanyUsers([])
      }
    }
  }

  useEffect(() => {
    company && loadCompanyStores()
    company && loadCompanyConfig()
    company && loadUsers()
  }, [company])

  useEffect(() => {
    loadUsers()
  }, [store])

  useEffect(() => {
    store && loadAdminUsers()
  }, [store])

  useEffect(() => {
    if (store && userData.role.id === 'role1') {
      fetchPathologies(company.id)
    } else fetchPathologies(userData.companyId)
  }, [store])

  useEffect(() => {
    if (userData && !dataToEdit && userData.role.id !== ('role1' || 'role3'))
      setUserResponsable({ name: userData.name + ' ' + userData.lastname + ' ' + userData?.lastname2, id: userData.id })

    if (userData.role.id === 'role3' && userData) loadCompanyStores()
  }, [userData, openModal])

  const onSave = async () => {
    setLoading(true)
    const newIncident = JSON.parse(JSON.stringify(values))
    newIncident.updatedAt = Date.now()
    newIncident.userResponsable = userResponsable
    newIncident.process = processSelected
    newIncident.machine = machineSelected
    newIncident.sector = sectorSelected
    newIncident.testigos = testigos
    newIncident.whoParticipatedAnalyse = whoParticipatedAnalyse
    newIncident.timeWindow = timeWindow
    newIncident.affectedZone = affectedZone
    newIncident.how = how
    newIncident.injuredWorker = injuredWorker
    newIncident.where = areaSelected

    try {
      if (!dataToEdit) {
        newIncident.createdAt = Date.now()
        newIncident.createdBy = userData.email
        if (userData.role.id !== 'role1' && userData.role.id !== 'role3') {
          newIncident.companyId = userData.companyId
          newIncident.storeId = userData.storeId
          newIncident.company = userData.company
          newIncident.store = userData.store
        } else if (userData.role.id === 'role1') {
          newIncident.companyId = company.id
          newIncident.storeId = store.id
          newIncident.company = company
          newIncident.store = store
        } else if (userData.role.id === 'role3') {
          newIncident.companyId = userData.companyId
          newIncident.company = userData.company
          newIncident.storeId = store.id
          newIncident.store = store
        }

        await createIncident(newIncident)
        // await sendIncidentEmail(newIncident)
        showSnackbar('success', labels.es.incidentCreated)
      } else {
        await updateIncident(newIncident)
        showSnackbar('success', labels.es.incidentUpdated)
      }
      resetComponent()
      if (fetchData) fetchData()
      closeModal()
    } catch (error: any) {
      showSnackbar('error', error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = () => {
    if (dataToEdit && onDelete) onDelete(dataToEdit.id)
  }

  return (
    <Modal
      title={editing ? labels.es.editIncident : labels.es.incidentResearch}
      open={openModal}
      handleClose={closeModal}
      footer={
        <FooterForm
          text={labels.es.incident}
          closeModal={closeModal}
          onSave={onSave}
          onDelete={onDelete ? handleDelete : undefined}
          editing={editing}
          loading={loading}
          disabled={
            !injuredWorker ||
            !values.description ||
            !values.product ||
            !processSelected ||
            !userResponsable ||
            !sectorSelected ||
            !timeWindow ||
            !areaSelected ||
            !affectedZone ||
            !how ||
            !values.when ||
            !values.why ||
            !values.whatHappened
          }
        />
      }
    >
      <Box id="form-incident">
        {/* {editing && (
          <TextInput disabled value={values.incidentId} name="incidentId" text={labels.es.incidentId} placeholder={labels.es.incidentId} />
        )} */}
        {userData.role.id === 'role1' && (
          <SelectDialog
            text={labels.es.company}
            setData={setCompany}
            data={company}
            options={allCompanies
              .filter((elm: any) => !elm.deleted)
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((elm: any) => {
                return { name: elm.name, id: elm.id }
              })}
          />
        )}
        {allCompanyStores.length > 0 && ['role1', 'role3'].includes(userData.role.id) && (
          <SelectDialog
            text={labels.es.store}
            setData={setStore}
            data={store}
            options={allCompanyStores
              .filter((elm: any) => !elm.deleted)
              .sort((a, b) => a.storeName.localeCompare(b.storeName))
              .map((elm: any) => {
                return { name: elm.storeName, id: elm.id }
              })}
          />
        )}
        <TextInput
          required
          multiline
          rows={3}
          value={values.description}
          handleChange={(e) => handleChange(e)}
          name="description"
          text={labels.es.description}
          placeholder={labels.es.description}
        />
        {companyUsers.length > 0 && (
          <SelectDialog
            required
            text={labels.es.userResponsable}
            setData={setUserResponsable}
            data={userResponsable}
            options={companyUsers
              .filter((elm: any) => !elm.deleted)
              .sort((a: any, b: any) => a.name.localeCompare(b.name))
              .map((elm: any) => {
                return { name: `${elm.name} ${elm.lastname || ''} ${elm.lastname2 || ''}`, id: elm.id, employeeId: elm.employeeId }
              })}
          />
        )}
        {companyUsers.length > 0 && (
          <SelectDialog
            required
            text={labels.es.worker}
            setData={setInjuredWorker}
            data={injuredWorker}
            options={companyUsers
              .filter((elm: any) => !elm.deleted)
              .sort((a: any, b: any) => a.name.localeCompare(b.name))
              .map((elm: any) => {
                return { name: `${elm.name} ${elm.lastname || ''} ${elm.lastname2 || ''}`, id: elm.id, employeeId: elm.employeeId }
              })}
          />
        )}
        <SelectDialog
          required
          text={labels.es.workWindow}
          setData={setTimeWindow}
          data={timeWindow}
          options={[
            { name: 'Mañana', id: 'morning' },
            { name: 'Tarde', id: 'afternoon' }
          ]}
        />
        <TextInput
          required
          type={'number'}
          value={values.daysSinceDayOff}
          handleChange={(e) => handleChange(e)}
          name="daysSinceDayOff"
          text={labels.es.daysSinceDayOff}
          placeholder={labels.es.daysSinceDayOff}
        />
        {sectors.length > 0 && userData.role.id !== 'role1' && (
          <SelectDialog
            required
            text={labels.es.sector}
            setData={setSectorSelected}
            data={sectorSelected}
            options={sectors
              .filter((elm) => !elm.deleted && elm.sectorType.id === 'Almacén')
              .map((elm: any) => {
                return { name: elm.name, id: elm.id }
              })
              .sort((a: any, b: any) => a.name.localeCompare(b.name))}
          />
        )}
        {configCompanyAdmin && userData.role.id === 'role1' && (
          <>
            <SelectDialog
              required
              text={labels.es.sector}
              setData={setSectorSelected}
              data={sectorSelected}
              options={configCompanyAdmin
                .filter((elm: any) => elm.type === 'sectors' && !elm.deleted && elm.sectorType.id === 'Almacén')
                .map((elm: any) => {
                  return { name: elm.name, id: elm.id }
                })
                .sort((a: any, b: any) => a.name.localeCompare(b.name))}
            />
          </>
        )}
        {processes.length > 0 && userData.role.id !== 'role1' && (
          <SelectDialog
            required
            text={labels.es.process}
            setData={setProcessSelected}
            data={processSelected}
            options={processes
              .filter((elm) => !elm.deleted)
              .map((elm: any) => {
                return { name: elm.name, id: elm.id }
              })
              .sort((a: any, b: any) => a.name.localeCompare(b.name))}
          />
        )}
        {configCompanyAdmin && userData.role.id === 'role1' && (
          <>
            <SelectDialog
              required
              text={labels.es.process}
              setData={setProcessSelected}
              data={processSelected}
              options={configCompanyAdmin
                .filter((elm: any) => elm.type === 'processes' && !elm.deleted)
                .map((elm: any) => {
                  return { name: elm.name, id: elm.id }
                })
                .sort((a: any, b: any) => a.name.localeCompare(b.name))}
            />
          </>
        )}
        <TextInput
          required
          value={values.product}
          handleChange={(e) => handleChange(e)}
          name="product"
          text={labels.es.product}
          placeholder={labels.es.product}
        />
        {machines.length > 0 && userData.role.id !== 'role1' && (
          <SelectDialog
            optionalField
            text={labels.es.machine}
            setData={setMachineSelected}
            data={machineSelected}
            options={machines
              .filter((elm) => !elm.deleted)
              .map((elm: any) => {
                return { name: elm.name, id: elm.id }
              })
              .sort((a: any, b: any) => a.name.localeCompare(b.name))}
          />
        )}
        {configCompanyAdmin && userData.role.id === 'role1' && (
          <>
            <SelectDialog
              optionalField
              text={labels.es.machine}
              setData={setMachineSelected}
              data={machineSelected}
              options={configCompanyAdmin
                .filter((elm: any) => elm.type === 'machines' && !elm.deleted)
                .map((elm: any) => {
                  return { name: elm.name, id: elm.id }
                })
                .sort((a: any, b: any) => a.name.localeCompare(b.name))}
            />
          </>
        )}
        <TextInput
          required
          multiline
          rows={3}
          value={values.whatHappened}
          handleChange={(e) => handleChange(e)}
          name="whatHappened"
          text={labels.es.whatHappened}
          placeholder={labels.es.whatHappened}
        />
        {areas.length > 0 && userData.role.id !== 'role1' && (
          <SelectDialog
            required
            text={labels.es.where}
            setData={setAreaSelected}
            data={areaSelected}
            options={[
              ...areas.filter((elm) => !elm.deleted)
              // { name: 'Tubo Logístico', id: 'tuboLogistico' },
              // { name: 'Salida Gran Volumen', id: 'salidaGV' },
              // { name: 'E-commerce', id: 'ecommerce' },
              // { name: 'Parking Exterior', id: 'parking' }
            ]
              .sort((a: any, b: any) => a.name.localeCompare(b.name))
              .map((elm: any) => {
                return { name: elm.name, id: elm.id }
              })
              .sort((a, b) => a.name.localeCompare(b.name))}
          />
        )}
        {configCompanyAdmin && userData.role.id === 'role1' && (
          <>
            <SelectDialog
              required
              text={labels.es.where}
              setData={setAreaSelected}
              data={areaSelected}
              options={[...configCompanyAdmin.filter((elm: any) => elm.type === 'areas' && !elm.deleted)]
                .sort((a: any, b: any) => a.name.localeCompare(b.name))
                .map((elm: any) => {
                  return { name: elm.name, id: elm.id }
                })}
            />
          </>
        )}
        <TextInput
          required
          value={values.when}
          handleChange={(e) => handleChange(e)}
          name="when"
          id="when"
          text={labels.es.when}
          placeholder={labels.es.when}
          type="date"
        />
        <TextInput
          required
          multiline
          rows={5}
          value={values.why}
          handleChange={(e) => handleChange(e)}
          name="why"
          text={labels.es.why}
          placeholder="Para completar esta casilla analizar la causa raíz verificando los factores que aplican al colaborador, máquina, entorno, producto, procedimiento de trabajo, que corrigiendolos habrían evitado la ocurrencia del accidente"
        />
        <SelectDialog
          required
          text={labels.es.how}
          setData={setHow}
          data={how}
          options={[
            { name: 'De forma individual', id: 'alone' },
            { name: 'Con ayuda de otro colaborador', id: 'morePeople' }
          ].map((elm: any) => {
            return { name: elm.name, id: elm.id }
          })}
        />
        <SelectDialog
          text={labels.es.affectedZone}
          required
          setData={setAffectedZone}
          data={affectedZone}
          options={pathologies.filter((elm: any) => !elm.deleted).sort((a: any, b: any) => a.name.localeCompare(b.name))}
        />
        {companyUsers.length > 0 && (
          <ChipSelector
            // fieldToSave="id"
            data={testigos}
            fieldToSave="labels"
            setData={setTestigos}
            options={companyUsers
              .filter((elm: any) => !elm.deleted)

              .sort((a: any, b: any) => a.name.localeCompare(b.name))
              .map((elm: any) => {
                return { name: `${elm.name} ${elm.lastname || ''} ${elm.lastname2 || ''}`, id: elm.id, employeeId: elm.employeeId }
              })}
            text={labels.es.testigos}
          />
        )}

        {companyUsers.length > 0 && (
          <ChipSelector
            // fieldToSave="id"
            fieldToSave="labels"
            data={whoParticipatedAnalyse}
            setData={setWhoParticipatedAnalyse}
            options={companyUsers
              .filter((elm: any) => !elm.deleted)

              .sort((a: any, b: any) => a.name.localeCompare(b.name))
              .map((elm: any) => {
                return { name: `${elm.name} ${elm.lastname || ''} ${elm.lastname2 || ''}`, id: elm.id, employeeId: elm.employeeId }
              })}
            text={labels.es.whoParticipatedAnalyse}
          />
        )}
      </Box>
    </Modal>
  )
}
